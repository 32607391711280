import "./On_Page_Optimization.css"
import img1 from "./img1.png"
import img2 from "./img2.png"
import checkMark from "./icon.png"

export default function On_Page_Optimization() {
	return (
		<div className='row'>
			<div className='on-page-optimization-wrapper'>
				<p
					className='text-center font-bold font-15'
					style={{ color: "#FF0798" }}
				>
					All Websites Include
				</p>
				<h1 className='font-57 color-navy text-center'>On-Page Optimization</h1>
				<div className='optimation-card-container mt-35'>
					<div className='optimation-card'>
						<img src={img1} />
						<h3 className='font-24 font-bold text-uppercase mt-40'>
							Website Optimization
						</h3>
						<div className='optimation-card-text-container mt-35'>
							<div className='optimation-card-text'>
								<img src={checkMark} />
								<p className='font-16 font-regular'>
									Title & Meta Tag Optimization
								</p>
							</div>
							<div className='optimation-card-text'>
								<img src={checkMark} />
								<p className='font-16 font-regular'>Content Optimization</p>
							</div>
							<div className='optimation-card-text'>
								<img src={checkMark} />
								<p className='font-16 font-regular'>Image Optimization</p>
							</div>
							<div className='optimation-card-text'>
								<img src={checkMark} />
								<p className='font-16 font-regular'>Alt Tag Optimization</p>
							</div>
							<div className='optimation-card-text'>
								<img src={checkMark} />
								<p className='font-16 font-regular'>Internal Linking</p>
							</div>
						</div>
					</div>
					<div className='optimation-card'>
						<img src={img2} />
						<h3 className='font-24 font-bold text-uppercase mt-40'>
							Technical Optimization
						</h3>
						<div className='optimation-card-text-container mt-35'>
							<div className='optimation-card-text'>
								<img src={checkMark} />
								<p className='font-16 font-regular'>Sitemap Creation and </p>
							</div>
							<div className='optimation-card-text'>
								<img src={checkMark} />
								<p className='font-16 font-regular'>Submission</p>
							</div>
							<div className='optimation-card-text'>
								<img src={checkMark} />
								<p className='font-16 font-regular'>Website Caching</p>
							</div>
							<div className='optimation-card-text'>
								<img src={checkMark} />
								<p className='font-16 font-regular'>htaccess optimization</p>
							</div>
							<div className='optimation-card-text'>
								<img src={checkMark} />
								<p className='font-16 font-regular'>Robots.txt file Creation</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='l-height-100'></div>
			<div className='optimization-seo-text'>
				<p className='font-20 l-height-30 text-center'>
					Our Local SEO packages are compliant with Google Webmaster Guidelines
					100%. Our clients never fear the next Google update. In fact, they
					actually look forward to them! With each major update we’ve seen our
					client rankings improve. Why? Because when an update is released, and
					your competitor’s website is hit with a new penalty, their rankings go
					down. For our clients, that means theirs go up! Our Local SEO Packages
					will deliver significant increase in traffic to your website, and more
					customers for your business.
				</p>
				<br />
				<p className='font-20 l-height-30 text-center'>
					Our Local SEO efforts also include on-page conversion optimization
					strategies designed to turn those visitors into paying customers.
					There’s no point in driving traffic to your website if it doesn’t have
					a significant impact on the bottom line. When potential customers are
					searching the web for a product or service, there is a good chance
					they’re ready to buy.
				</p>
				<br />
				<p className='font-20 l-height-30 text-center'>
					Our Local SEO package is an excellent SEO package for small
					business!When your clients’ competitors are the first thing they see,
					guess who gets the sale? Getting those potential customers through the
					front door of your clients’ building starts with making your client
					discoverable on the web. We accomplish that through citation building,
					establishing high-quality backlinks, and creating a local reputation
					online that drives foot traffic.
				</p>
			</div>
		</div>
	)
}
