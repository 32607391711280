import "./Looking_To_Attract_More.css"
import bg from "./images/bg.png"
import google from "./images/google.png"
import lookingToAttractImg from "./images/looking-to-attract.png"

export default function Looking_To_Attract_More() {
	return (
		<div className='looking-to-attract-container'>
			<div className='looking-to-attract-wrapper row'>
				<div className='looking-to-attract-image-container'>
					<img src={lookingToAttractImg} />
					<img src={google} className='mt-50' />
				</div>
				<div className='looking-to-attract-card-text'>
					<h1 className='font-40 l-height-50 font-bold color-navy'>
						Looking to Attract More Customers with Local SEO Services?
					</h1>
					<p
						className='font-18 font-regular mt-20 l-height-27'
						style={{ color: "#2D2C2C" }}
					>
						Imagine your large or small business being discovered by hundreds of
						prospective customers on Google Maps every day! Choosing the best
						local SEO company with experience in your industry will get you
						there—it's actually the number one problem we solve for our client
						every day!
						<br />
						<br />
						<p className='font-bold'>How do we do this?</p>
						<br />
						We work directly with our clients to attract the right type of
						potential customer for their local business. It takes a bit more
						time, but the results speak for themselves. Local SEO services start
						with smart local SEO campaign research. We pay attention to each and
						every SEO detail for you. Digital Shift reviews each keyword for
						competitiveness, search frequency, and commercial intent to ensure
						we use only the best keywords in your local SEO campaign.
						<br />
						<br />
						Our digital marketing experts will help you launch the perfect local
						SEO services campaign that's specifically designed to outrank
						competitors in your local market, scoring you more traffic and
						customers for your business.
					</p>
					<button className='looking-to-attract-btn font-bold font-20'>
						Start Your SEO
					</button>
				</div>
			</div>
		</div>
	)
}
