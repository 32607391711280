import Business_Support from "../../layaout/Business_Support/Business_Support"
import "./About_Us.css"

export default function About_Us() {
	return (
		<div>
			<div className='about-us-header-container'>
				<h1 className='font-77 color-white text-center'>About Us</h1>
			</div>
			<div className='about-us-card-wrapper'>
				<div style={{ display: "flex", alignItems: "center" }}>
					<div
						style={{
							width: "70px",
							height: "1.5px",
							background: "#030176",
						}}
					></div>
					<p
						style={{
							fontSize: "15px",
							marginLeft: "19px",
							color: "#FF0798",
						}}
						className='font-bold'
					>
						Kris Chislett Design - A Web Design Agency Jacksonville
					</p>
				</div>
				<h1 className='font-42 about-us-card-title mt-23'>
					Kris Chislett Design - A Web Design Agency Jacksonville
				</h1>
				<div
					className='grid-3'
					style={{ rowGap: "42px", columnGap: "46px", marginTop: "38.5px" }}
				>
					<div className='about-us-card'>
						<h1 className='font-bold font-36 l-height-43'>Collaboration</h1>
						<p
							className='font-regular font-16 l-height-24 mt-22'
							style={{ color: "#2D2C2C" }}
						>
							Our team of designers, developers, and project innovators are more
							than capable of working unaided, but we always push for a
							collaborative approach to transform businesses and make visions
							and goals into a reality.
						</p>
					</div>
					<div className='about-us-card'>
						<h1 className='font-bold font-36 l-height-43'>Transparency</h1>
						<p
							className='font-regular font-16 l-height-24 mt-22'
							style={{ color: "#2D2C2C" }}
						>
							Our team of designers, developers, and project innovators are more
							than capable of working unaided, but we always push for a
							collaborative approach to transform businesses and make visions
							and goals into a reality.
						</p>
					</div>
					<div className='about-us-card'>
						<h1 className='font-bold font-36 l-height-43'>Passion</h1>
						<p
							className='font-regular font-16 l-height-24 mt-22'
							style={{ color: "#2D2C2C" }}
						>
							Our team of designers, developers, and project innovators are more
							than capable of working unaided, but we always push for a
							collaborative approach to transform businesses and make visions
							and goals into a reality.
						</p>
					</div>
					<div className='about-us-card'>
						<h1 className='font-bold font-36 l-height-43'>Collaboration</h1>
						<p
							className='font-regular font-16 l-height-24 mt-22'
							style={{ color: "#2D2C2C" }}
						>
							Our team of designers, developers, and project innovators are more
							than capable of working unaided, but we always push for a
							collaborative approach to transform businesses and make visions
							and goals into a reality.
						</p>
					</div>
					<div className='about-us-card'>
						<h1 className='font-bold font-36 l-height-43'>Transparency</h1>
						<p
							className='font-regular font-16 l-height-24 mt-22'
							style={{ color: "#2D2C2C" }}
						>
							Our team of designers, developers, and project innovators are more
							than capable of working unaided, but we always push for a
							collaborative approach to transform businesses and make visions
							and goals into a reality.
						</p>
					</div>
					<div className='about-us-card'>
						<h1 className='font-bold font-36 l-height-43'>Passion</h1>
						<p
							className='font-regular font-16 l-height-24 mt-22'
							style={{ color: "#2D2C2C" }}
						>
							Our team of designers, developers, and project innovators are more
							than capable of working unaided, but we always push for a
							collaborative approach to transform businesses and make visions
							and goals into a reality.
						</p>
					</div>
				</div>
			</div>
			<div className='webdesign-digital-marketing-wrapper'>
				<h1 className='font-45 l-height-40 color-white font-bold text-center'>
					Webdesign and digital Markwting{" "}
				</h1>
				<div className='webdesign-digital-marketing-content-container'>
					<p className='text-center font-35 font-regular l-height-40 mt-35 color-white'>
						As a Web Design and Digital Marketing agency for businesses, we know
						its critical to bring to our clients world-class web design
						services, powerful digital marketing and centralized dedicated FREE
						monthly support.
						<br />
						<br /> Over the years, our client value has evolved and grown into a
						much-needed true full service solution for businesses. Not only in
						Tennessee, but nationally servicing large and small businesses. Our
						solutions solve the multi-vendor DIY problem and provide businesses
						with a big competitive advantage online without a huge budget,
						confusion and poor support.
					</p>
				</div>
			</div>

			<div className='about-us-creative-dedicated-section'>
				<div className='about-us-creative-dedicated-section-content'>
					<h1
						className='font-bold font-39 l-height-50'
						style={{ color: "#030176" }}
					>
						Creative, Dedicated, Driven, Punctual. We're Committed to Seeing Our
						Clients Grow and Outperform the Competition.
					</h1>
					<p className='font-regular font-17 l-height-24 mt-20'>
						What started as a one-man show has grown into a full-service
						creative and digital marketing agency.
						<br />
						<br />
						Today, Kris Chislett, A Web Design Agency Jacksonville boasts a
						unique, close-knit team with experts in all facets of website
						development, content creation, design and marketing.
						<br />
						<br />
						We believe our true competitive advantage is the quality of our
						team. As we grow, we’re committed to only hiring and developing the
						very best people who strive for continuous innovation and extreme
						customer focus.
					</p>
					<button className='mt-22 about-us-creative-dedicated-btn font-20 font-bold'>
						Meet The Team
					</button>
				</div>
			</div>

			<Business_Support />
		</div>
	)
}
