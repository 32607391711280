import "./Google_My_Business_Hero.css"
import img from "./img.png"

export default function Google_My_Business_Hero() {
	return (
		<div className='google-my-business-wrapper'>
			{/* <div className='gutter-lg-google-my-business'></div> */}
			<div className='gmb-container row'>
				<div className='social_media_first_part'>
					<h1 className='gmb-title font-52'>Google My Business</h1>
					<h1 className='font-52 color-white gmb-sub-title '>
						Management Service
					</h1>
					<p className='color-white font-17 l-height-24 mt-20'>
						We specialize in affordable Google Business management for
						businesses looking to grow online.
					</p>
					<div className='font-17 color-white font-medium mt-20'>
						<p className='l-height-24'>• Improve search results</p>
						<p className='l-height-24'>• Get new customers</p>
						<p className='l-height-24'>• Improve maps exposure</p>
					</div>
					<button className='book-a-call-btn font-25 mt-35'>
						Book a Discovery Call With US
					</button>
					<div className='info-container color-white font-bold font-22 mt-25'>
						<span className='info-container-phone'>(323) 920-0350</span>
						<span className='info-container-email'>info@nahid.com</span>
					</div>
				</div>
				<div className='social_media_second_part'>
					<img src={img} />
				</div>
			</div>
			{/* <div className='gutter-lg-google-my-business'></div> */}
		</div>
	)
}
