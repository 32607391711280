import "./Website_Constructor.css"
import img from "./img.png"

export default function Website_Constructor() {
	return (
		<div className='website-contractor-container'>
			<div class='website-contractor-wrapper row'>
				<div class='website-contractor-img-container'>
					<img src={img} />
				</div>
				<div class='website-contractor-card-text'>
					<h1 class='font-40 l-height-50 font-bold color-navy'>
						WEBSITES FOR
						<br />
						CONTRACTORS
					</h1>
					<p
						class='font-18 font-regular l-height-27 mt-25'
						style={{ color: "#2D2C2C" }}
					>
						Having a website is one of the most important marketing assets for
						any home services company, so if the site isn't growing the business
						for them, then it's time to make some changes to it. The number of
						people who search Google on a daily basis exceeds 3.5
						billion. Today's digital landscape requires every company to have a
						website because it acts as a virtual sales representative. When your
						website isn't mobile-friendly, outdated, or doesn't convert visitors
						into leads, you need professional website design services from a
						home service marketing company.
					</p>
					<div
						style={{
							display: "grid",
							gridTemplateColumns: "auto 1fr",
							gap: "13px",
						}}
						className='mt-50'
					>
						<div
							style={{
								width: "4px",
								background: "#ff0798",
								alignSelf: "stretch",
							}}
						></div>
						<div class='font-18 font-regular l-height-27'>
							<p>
								“The benefits (of Mobile Responsive Websites) are obvious: You
								build a website once, and it works seamlessly across thousands
								of different screens .”
							</p>
							<p class='text-right'>– Pete Cashmore, CEO Mashable</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
