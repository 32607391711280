import "./Google_Advertising.css"
import img1 from "./images/img-1.png"
import img2 from "./images/img-2.png"
import img3 from "./images/img-3.png"
import img4 from "./images/img-4.png"

export default function Google_Advertising() {
	return (
		<div className='google-advertising-wrapper row'>
			<h1 className='font-57 l-height-62 text-center color-navy'>
				WE MANAGE GOOGLE ADVERTISING FOR IHOME SERVICES COMPANIES.
			</h1>
			<p className='google-advertising-paragraph text-center font-regular l-height-24 mt-35'>
				A nice design with a strong visual identity is undoubtedly important for
				a professional website. It is about standing out from the crowd when the
				nearest competitor is only a click away. But what lies under the "hood"
				and the way it is all put together has a great influence on the results
				a company gets from its website. alpha marketing specializes in web
				design that is both beautiful and optimized to perform at its best. The
				goal is a more visible and effective website, which contributes to your
				company being able to create a better business.
			</p>
			<h1 className='l-height-41 font-29 font-medium text-center google-advertising-subtitle mt-30'>
				If you hire a Google Adwords specialist , here are some results you can
				expect:  
			</h1>
			<div className='google-advertising-card-container'>
				<div className='google-advertising-card'>
					<img src={img1} />
					<h3 className='font-bold font-24 l-height-29 color-navy mt-29'>
						A BUSINESS'S GOALS CAN BE ACHIEVED THROUGH PPC
					</h3>
					<p className='font-16 font-regular l-height-24 mt-16'>
						For most business owners, this is the most compelling justification
						for adopting PPC advertising. The ability of PPC to accomplish a
						range of commercial and marketing objectives is what distinguishes
						it as a strong investment. These objectives could be anything from
						thought leadership, hot lead submission, high-level brand exposure,
						or even an e-commerce transaction.
					</p>
				</div>
				<div className='google-advertising-card'>
					<img src={img2} />
					<h3 className='font-bold font-24 l-height-29 color-navy mt-29'>
						IMPLEMENTING PPC IN A MEASURABLE WAY
					</h3>
					<p className='font-16 font-regular l-height-24 mt-16'>
						Measurement and tracking are two of the major benefits of Google ads
						advertising. In addition to clicks, impressions, and conversions, we
						provide you with detailed performance reports using Google Ads and
						Google Analytics.
					</p>
				</div>
				<div className='google-advertising-card'>
					<img src={img3} />
					<h3 className='font-bold font-24 l-height-29 color-navy mt-29'>
						A TRACKABLE PPC STRATEGY
					</h3>
					<p className='font-16 font-regular l-height-24 mt-16'>
						If you haven't been involved in digital marketing, you can catch up
						to your rivals in a matter of days. Unlike other SEO initiatives,
						which demand a lot of attention and time to details, our Google
						Adwords campaign will be operational with thorough optimisation
					</p>
				</div>
				<div className='google-advertising-card'>
					<img src={img4} />
					<h3 className='font-bold font-24 l-height-29 color-navy mt-29'>
						CONTROL AND MONITORING ATTRIBUTES
					</h3>
					<p className='font-16 font-regular l-height-24 mt-16'>
						Our campaign will be tailored based on your suggestions and business
						objectives. You have complete control over how potential consumers
						are reached. You must choose the places or keywords you want to
						target for this and the level of restriction you want for them.
					</p>
				</div>
			</div>
		</div>
	)
}
