import "./Local_Seo_Packages_Hero.css"
import local_seo_hero_obj from "./local_seo_hero_obj.png"

export default function Local_Seo_Packages_Hero() {
	return (
		<div className='local-seo-wrapper'>
			{/* <div className='gutter-lg-seo'></div> */}
			<div className='local-seo-container row'>
				<div className='local_seo_first_part'>
					<h1 className='local-seo-title font-52'>Local Small Business</h1>
					<h1 className='font-77 color-white local-seo-packages-title '>
						SEO Packages
					</h1>
				</div>
				<div className='local_seo_second_part'>
					<img src={local_seo_hero_obj} />
				</div>
			</div>
			{/* <div className='gutter-lg-seo'></div> */}
		</div>
	)
}
