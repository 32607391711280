import "./Agencys_secret.css"
import agencysSecretImg from "./imgaes/agencys-secret-img.png"

export default function Agencys_secret() {
	return (
		<div className='agencys_secret_wrapper'>
			<div className='row'>
				<div style={{ height: "50px" }}></div>
				<div className='agencys_secret_container'>
					<div className='agencys_secret_image_container'>
						<img src={agencysSecretImg} />
					</div>
					<div className='agencys_secret_text_container'>
						<h3 className='font-40 font-bold l-height-50 color-navy agencys_secret_title'>
							AN AGENCY'S SECRET TO SUCCESS
						</h3>
						<p
							className='font-18 mt-18 l-height-27'
							style={{ color: "#2D2C2C" }}
						>
							The Google ads marketing method involves brands incurring costs
							when customers click on their ads. Bids are made based on the
							perceived value of a click based on keywords, platforms, and
							target audiences.
							<br />A Google ad campaign can be used to increase sales, promote
							brand awareness, and generate leads for a variety of campaigns.
							<br />
							With G-ads, relevance is just as important as with any other
							digital marketing tool. Ads can be shown at the exact time when
							users are searching for specific services, products, and
							information. With our help, you can run successful  campaigns
							through both target settings and account structure.
						</p>
					</div>
				</div>
				<div style={{ height: "50px" }}></div>
			</div>
		</div>
	)
}
