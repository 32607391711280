import "./Local_Seo_Packages.css"
import star from "./star.png"
import star1 from "./star-1.png"
import star2 from "./star-2.png"
import checkMark from "./check-mark.png"

export default function Local_Seo_Packages() {
	return (
		<div className='row'>
			<h1 className='font-57 color-navy grow-business-title text-center'>
				Local SEO Packages
			</h1>
			<div className='seo-packages-container'>
				<div className='starter-seo-package'>
					<div className='seo-package-img-container item-center'>
						<img src={star} />
					</div>
					<div className='package-name-container starter-package-name-container color-white'>
						<h3 className='font-24 font-semibold'>STARTER SEO</h3>
						<p className='font-14'>Focus: Citation Management</p>
					</div>
					<div className='package-services-container'>
						<div className='package-service'>
							<img src={checkMark} />
							<p>Initial Website Analysis</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p> Citation Building and</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p>Management</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p> Citation Match & Lock™</p>
						</div>
						<div className=' package-service'>
							<img src={checkMark} />
							<p>Google My Business</p>
						</div>
						<div className=' package-service'>
							<img src={checkMark} />
							<p>Optimization</p>
						</div>
						<div className=' package-service'>
							<img src={checkMark} />
							<p> Keyword Research</p>
						</div>
						<div className=' package-service'>
							<img src={checkMark} />
							<p>10 Keywords Tracked</p>
						</div>
						<div className=' package-service'>
							<img src={checkMark} />
							<p> Home Page Optimized</p>
						</div>
						<div className=' package-service'>
							<img src={checkMark} />
							<p> Site Technical Optimization</p>
						</div>
						<div className=' package-service'>
							<img src={checkMark} />
							<p>Competitive Analysis</p>
						</div>
						<div className=' package-service'>
							<img src={checkMark} />
							<p>Google Analytics Integration</p>
						</div>
						<div className=' package-service'>
							<img src={checkMark} />
							<p> Google Search Console</p>
						</div>
						<div className=' package-service'>
							<img src={checkMark} />
							<p>Integration</p>
						</div>
						<div className=' package-service'>
							<img src={checkMark} />
							<p>Monthly Analytics Reporting</p>
						</div>
					</div>
					<div className='seo-package-btn-container item-center mt-30 mb-50'>
						<button className='starter-seo-btn color-white font-bold'>
							GET FREE QOUTE
						</button>
					</div>
				</div>
				<div className='pro-seo-package'>
					<div className='seo-package-img-container item-center'>
						<img src={star1} />
					</div>
					<div className='package-name-container pro-package-name-container color-white'>
						<h3 className='font-24'>PRO SEO</h3>
						<p>Focus: Citation Management</p>
					</div>
					<div className='package-services-container'>
						<div className='package-service'>
							<img src={checkMark} />
							<p>Initial Website Analysis</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p> Citation Building and Management</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p> Citation Match & Lock™</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p>Google My Business</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p>Optimization</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p> Keyword Research</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p>10 Keywords Tracked</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p> Home Page Optimized</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p> Site Technical Optimization</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p>Competitive Analysis</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p>Google Analytics Integration</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p> Google Search Console</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p>Integration</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p>Monthly Analytics Reporting</p>
						</div>
					</div>
					<div className='seo-package-btn-container item-center mt-30 mb-50'>
						<button className='pro-seo-btn color-white font-bold'>
							GET FREE QOUTE
						</button>
					</div>
				</div>
				<div className='ultimate-seo-package'>
					<div className='seo-package-img-container item-center'>
						<img src={star2} />
					</div>
					<div className='package-name-container ultimate-package-name-container color-white'>
						<h3 className='font-24'>PRO SEO</h3>
						<p>Focus: Citation Management</p>
					</div>
					<div className='package-services-container'>
						<div className='package-service'>
							<img src={checkMark} />
							<p>Initial Website Analysis</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p> Citation Building and Management</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p> Citation Match & Lock™</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p>Google My Business</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p>Optimization</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p> Keyword Research</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p>10 Keywords Tracked</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p> Home Page Optimized</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p> Site Technical Optimization</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p>Competitive Analysis</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p>Google Analytics Integration</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p> Google Search Console</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p>Integration</p>
						</div>
						<div className='package-service'>
							<img src={checkMark} />
							<p>Monthly Analytics Reporting</p>
						</div>
					</div>
					<div className='seo-package-btn-container item-center mt-30 mb-50'>
						<button className='ultimate-seo-btn color-white font-bold'>
							GET FREE QOUTE
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
