import React from "react"
import ContactForm from "../ContactForm/ContactForm"
import "./Contact.css"
import rightArrow from "./right-arrow.svg"

export default function Contact() {
	return (
		<div className='contact-container-wrapper py-60' id='getInTouch'>
			<div className='contact-container'>
				{/* <div className='contact-form-container'>
					<div className='contact-form-title'>
						<h1 className='font-semibold'>Grow with the pros</h1>
					</div>
					<div className='contact-form-sub-title'>
						<p className='font-semibold'>
							Let us know about you to get started
						</p>
					</div>
					<form
						className='contact-form'
						action='https://formsubmit.co/tanveerctg2014@gmail.com'
						method='POST'
					>
						<div className='name-phone-container'>
							<input placeholder='Name' type='text' name='name' required />
							<input placeholder='Phone No' type='text' name='phone' />
						</div>
						<input placeholder='Email' type='email' name='email' required />
						<textarea
							placeholder='Tell us about your business...'
							rows='6'
							type='text'
							name='message'
							required
						></textarea>
						<input type='submit' className='submit-form-btn font-semibold' />
					</form>
					<div className='contact-no'>
						<p className='font-semibold'>
							Want to Know more? Give us call at <span>+8121231243</span>
						</p>
					</div>
				</div> */}
				<ContactForm />
				<div className='contact-information'>
					<div className='trusted-btn'>
						<div>
							<p>Trusted By 300+ Clients</p>
						</div>
						<div className='star-container'>
							{[0, 1, 2, 3, 4].map((star) => (
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									key={star}
								>
									<path d='M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z'></path>
								</svg>
							))}
						</div>
					</div>
					<p className='ready-to-speak'>
						Ready to speak with a marketing expert
					</p>
					<div className='call-our-team'>
						<p className='call-our-team-first'>Call our team @</p>
						<p className='call-our-team-second font-bold'>+12342354235</p>
					</div>
					<div className='marketing-text'>
						<p className='marketing-text-first font-semibold'>
							Our Pros have your back with
						</p>
						<p className='marketing-text-second font-semibold'>
							Award Winning Design And
						</p>
						<p className='marketing-text-third'>
							<span className='font-semibold marketing-keyword'>MArketing</span>
							, including proven systems focused
						</p>
						<p className='marketing-text-fourth'>
							on producing the best return on your investment.
						</p>
					</div>
					<div className='marketing-text-final'>
						<p className='marketing-text-final-first font-semibold'>
							Las vegas Web design & Marketing agency ready to grow your
							business.
						</p>
						<p className='marketing-text-final-second font-semibold'>
							Schedule a free strategy call. +198345345
						</p>
					</div>
				</div>
				{/* <div className='schedule-appointment'>
					<div className='schedule-appointment-wrapper'>
						<div className='schedule-appointment-wrapper'>
							<h1>Schedule an Appointment</h1>
						</div>
						<div className='schedule-appointment-body'>
							<div className='h-40'></div>
							<div className='divider'></div>
							<div className='schedule-appointment-content-wrapper'>
								<div className='schedule-appointment-content-header'>
									<div style={{ display: "flex", alignItems: "center" }}>
										<div
											style={{
												width: "20px",
												height: "20px",
												borderRadius: "50%",
												background: "#fba315",
											}}
										></div>
										<p className='font-semibold' style={{ marginLeft: "10px" }}>
											Virtual Meeting
										</p>
									</div>
									<img src={rightArrow} />
								</div>
								<p className='schedule-appointment-content'>
									Schedule your virtual meeting with eNet Web Services from the
									comfort of your home!
								</p>
							</div>
							<div className='divider'></div>
							<div className='schedule-appointment-content-wrapper'>
								<div className='schedule-appointment-content-header'>
									<div style={{ display: "flex", alignItems: "center" }}>
										<div
											style={{
												width: "20px",
												height: "20px",
												borderRadius: "50%",
												background: "#229cfe",
											}}
										></div>
										<p className='font-semibold' style={{ marginLeft: "10px" }}>
											In-Office Meeting
										</p>
									</div>
									<img src={rightArrow} />
								</div>
								<p className='schedule-appointment-content'>
									Schedule an in-office meeting with eNet Web Services at our
									main office in West Chester!
								</p>
							</div>
							<div className='divider'></div>
							<div className='schedule-appointment-content-wrapper'>
								<div className='schedule-appointment-content-header'>
									<div style={{ display: "flex", alignItems: "center" }}>
										<div
											style={{
												width: "20px",
												height: "20px",
												borderRadius: "50%",
												background: "#f7e240",
											}}
										></div>
										<p className='font-semibold' style={{ marginLeft: "10px" }}>
											Phone Call
										</p>
									</div>
									<img src={rightArrow} />
								</div>
								<p className='schedule-appointment-content'>
									Schedule a phone call with eNet Web Services to go over the
									details of your project!
								</p>
							</div>
							<div className='h-40'></div>
							<p
								style={{
									color: "#229cfe",
									textAlign: "center",
									fontSize: "12px",
									paddingBottom: "5px",
								}}
							>
								Cookie Setting
							</p>
						</div>
					</div>
				</div> */}
			</div>
		</div>
	)
}
