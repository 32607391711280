import { useState } from "react"
import logo from "./Logo.png"
import "./Header.css"
import { NavLink, useNavigate } from "react-router-dom"
import MobileNav from "./MobileNav"
import phoneImg from "./phone.png"
import phoneMobile from "./phone-black.png"

export default function Header() {
	const [navigationOpen, setNavigationOpen] = useState(false)

	let activeStyle = {
		textDecoration: "none",
		color: "#002ED6",
		fontSize: "17px",
		fontWeight: 600,
	}

	let inactiveStyle = {
		color: "#000000",
		textDecoration: "none",
		fontSize: "17px",
		fontWeight: 600,
	}
	return (
		<>
			<div className='header-wrapper'>
				<div className='header-container row'>
					<NavLink to='/home1'>
						<img src={logo} className='brand-logo' />
					</NavLink>
					<div className='free-seo-analysis-container'>
						<div className='desktop-free-seo-contact'>
							<img src={phoneImg} />
							<span
								className='font-23 font-bold color-white'
								style={{ marginLeft: "14.5px" }}
							>
								01784856059
							</span>
						</div>
						<button className='desktop-free-seo-analysis-btn'>
							Free SEO Analysis
						</button>

						<svg
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 448 512'
							fill='white'
							size='lg'
							className='hamburger-menu'
							onClick={() => setNavigationOpen(true)}
						>
							<path d='M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z' />
						</svg>
					</div>
				</div>
			</div>
			<div className='mobile-free-seo-analysis'>
				<div className='mobile-free-seo-contact'>
					<img src={phoneMobile} />
					<span className='font-23 font-bold' style={{ marginLeft: "14.5px" }}>
						01784856059
					</span>
				</div>
				<button className='mobile-free-seo-analysis-btn'>
					Free SEO Analysis
				</button>
			</div>
			<MobileNav
				navigationOpen={navigationOpen}
				navigationClose={() => setNavigationOpen(false)}
			/>
			<div className='desktop-navbar'>
				<NavLink
					to='/home1'
					style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
				>
					{" "}
					Home{" "}
				</NavLink>

				<NavLink
					to='/seo'
					style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
				>
					{" "}
					Local Seo{" "}
				</NavLink>
				<NavLink
					to='/web-design'
					style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
				>
					{" "}
					Web Design{" "}
				</NavLink>
				{/* <NavLink
					to='/increase-sales'
					style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
				>
					{" "}
					Increase Sales{" "}
				</NavLink> */}

				<NavLink
					to='/Google_My_Business'
					style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
				>
					Google My Business
				</NavLink>
				<NavLink
					to='/Local_Seo_Packages'
					style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
				>
					Local Seo Packages
				</NavLink>
				<NavLink
					to='/about-us'
					style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
				>
					{" "}
					About Us{" "}
				</NavLink>
				<NavLink
					to='/contact'
					style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
				>
					{" "}
					Contact Us{" "}
				</NavLink>
			</div>
		</>
	)
}
