import "./Gmb_Management_Services.css"
import img from "./img.png"
export default function Gmb_Management_Services() {
	return (
		<div className='row'>
			<div className='gmb-management-services-wrapper'>
				<h1 className='font-57 text-center color-navy gmb-management-services-title'>
					GMB Management Services Will Help You Get Found on Google
				</h1>
				<p className='font-24 mt-30 l-height-30 text-center'>
					We'll manage your business listing and help you get found locally on
					Google.
				</p>

				<div className='gmb-management-services-img-text-wrapper mt-60'>
					<div className='gmb-management-services-img-container'>
						<img src={img} />
					</div>
					<div>
						<h3 className='font-bold font-39 l-height-50 color-navy'>
							Top Reasons For Google My Business Management Services
						</h3>
						<div
							className='mt-27 font-17 font-medium'
							style={{ color: "#2D2C2C" }}
						>
							<p className='l-height-25'>
								1. To show up in Google Map Searches / 3-Pack
							</p>
							<p className='l-height-25'>
								2. Helps Make You Stand Out from Competitors
							</p>
							<p className='l-height-25'>
								3. Star Ratings (Reviews) Boost Your Appeal
							</p>
							<p className='l-height-25'>4. Earns Trust From Customers</p>
							<p className='l-height-25'>
								5. Increases Traffic To Your Website
							</p>
							<p className='l-height-25'>6. Increases Brand Awareness</p>
							<p className='l-height-25'>
								7. It’s Great For Advertising Your Business
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
