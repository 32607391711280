import "./Web_Design_Hero.css"
import web_design_img from "./img.png"

export default function Web_Design_Hero() {
	return (
		<div className='web-design-wrapper'>
			<div className='gutter-lg-web-design'></div>
			<div className='web-design-container row'>
				<div className='web_design_first_part'>
					<h1 className='web-design-title font-77'>Web Design</h1>
					<h1 className='font-77 color-white web-design-subtitle '>Services</h1>
					<p
						className='color-white font-17 l-height-24 font-medium mt-15'
						style={{ maxWidth: "446px", width: "100%" }}
					>
						With a custom-designed website, you can have a reliable online
						presence that meets your company's quality, branding, and usability
						goals.
					</p>
					<button className='book-a-call-btn font-25 mt-70'>
						Book a Discovery Call With US
					</button>
					<div className='info-container color-white font-bold font-22 mt-20'>
						<span className='info-container-phone font-22 '>
							(323) 920-0350
						</span>
						<span className='info-container-email font-22 '>
							info@nahid.com
						</span>
					</div>
				</div>

				<img src={web_design_img} className='web_design_img' />
			</div>
			<div className='gutter-lg-web-design down'></div>
		</div>
	)
}
