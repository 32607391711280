import Grow_Your_Business from "./Grow_Your_Business/Grow_Your_Business"
import Local_Seo_Packages from "./Local_Seo_Packages/Local_Seo_Packages"
import Local_Seo_Packages_Hero from "./Local_Seo_Packages_Hero/Local_Seo_Packages_Hero"
import On_Page_Optimization from "./On_Page_Optimization/On_Page_Optimization"
import Who_We_Are from "./Who_We_Are/Who_We_Are"
import FAQ from "../../layaout/FAQ/FAQ"
import Contact from "../../layaout/Contact/Contact"

export default function _Local_Seo_packages() {
	return (
		<>
			<Local_Seo_Packages_Hero />
			<div className='l-height-130'></div>
			<Grow_Your_Business />
			<div className='l-height-130'></div>
			<Who_We_Are />
			<div className='l-height-130'></div>
			<Local_Seo_Packages />
			<div className='l-height-100'></div>
			<On_Page_Optimization />
			<div className='l-height-100'></div>
			<FAQ />
			<div className='l-height-100'></div>
			<Contact />
		</>
	)
}
