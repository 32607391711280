import "./FAQ_1.css"

export default function FAQ_1() {
	return (
		<div className='row'>
			<div className='faq_1_wrapper'>
				<h1 className='font-48 color-navy text-center l-height-48'>
					Frequently Asked Questions
				</h1>
				<p
					className='l-height-24 font-17 text-center mt-23 font-medium social_media_faq_paragraph'
					style={{ color: "#2D2C2C" }}
				>
					Some text here…We help bring service business owner more business by
					attracting more clients and customers through our proven local SEO
					services and digital marketing campaigns.We help bring service
					business owner more business by attracting more clients and customers
					through our proven local SEO services and digital marketing campaigns.
				</p>
				<div className='faq_1_questions grid-3 font-20'>
					<h5 className='font-semibold'>What is Email Marketing?​</h5>
					<h5 className='font-semibold'>
						Why should you hire a local <br />
						website support provider?​
					</h5>
					<h5 className='font-semibold'>
						What industries do you do marketing for?
					</h5>
					<h5 className='font-semibold'>
						How much does it cost to hire a <br />
						WordPress developer?
					</h5>
					<h5 className='font-semibold'>
						Do we do e-commerce website
						<br /> design?
					</h5>
					<h5 className='font-semibold'>
						What types of websites can we <br />
						build?
					</h5>
					<h5 className='font-semibold'>
						How much does a WordPress
						<br /> developer cost?
					</h5>
					<h5 className='font-semibold'>
						Is a WordPress developer a real
						<br /> developer?
					</h5>
					<h5 className='font-semibold'>Are you located in Las Vegas?</h5>
				</div>
			</div>
		</div>
	)
}
