import React, { useState } from "react"
import ReactDOM from "react-dom"
import logo from "./Logo.png"
import { useNavigate, NavLink } from "react-router-dom"
import "./Mobile-Nav.css"

export default function MobileNav({ navigationOpen, navigationClose }) {
	let navigate = useNavigate()
	const [isServiceMobileMenuOpen, setIsServiceMobileMenuOpen] = useState(false)
	let activeStyle = {
		textDecoration: "none",
		color: "#002ED6",
		fontSize: "22px",
		fontWeight: 600,
	}

	let inactiveStyle = {
		color: "#333333",
		textDecoration: "none",
		fontSize: "22px",
		fontWeight: 500,
	}
	// if (!navigationOpen) return null
	return ReactDOM.createPortal(
		<div
			className={`mobile-nav-wrapper ${navigationOpen && "open"}`}
			style={{
				position: "fixed",
				top: 0,
				left: 0,
				height: "100%",
				width: "100%",
				background: "transparent",
			}}
		>
			<div className='mobile-nav'>
				<NavLink
					to='/home1'
					style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
					onClick={() => navigationClose()}
				>
					{" "}
					Home{" "}
				</NavLink>

				<NavLink
					to='/seo'
					style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
					onClick={() => navigationClose()}
				>
					{" "}
					SEO{" "}
				</NavLink>
				<NavLink
					to='/web-design'
					style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
					onClick={() => navigationClose()}
				>
					{" "}
					Web Design{" "}
				</NavLink>
				{/* <NavLink
					to='/increase-sales'
					style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
					onClick={() => navigationClose()}
				>
					{" "}
					Increase Sales{" "}
				</NavLink> */}
				{/* <NavLink
					to='/google'
					style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
					onClick={() => navigationClose()}
				>
					{" "}
					Google Ads{" "}
				</NavLink> */}
				<NavLink
					to='/about-us'
					style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
					onClick={() => navigationClose()}
				>
					{" "}
					About Us{" "}
				</NavLink>
				{/* <NavLink
					to='/social-media'
					style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
					onClick={() => navigationClose()}
				>
					{" "}
					Social Media Marketing
				</NavLink> */}
				<NavLink
					to='/Google_My_Business'
					style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
					onClick={() => navigationClose()}
				>
					Google My Business
				</NavLink>
				<NavLink
					to='/Local_Seo_Packages'
					style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
					onClick={() => navigationClose()}
				>
					Local Seo Packages
				</NavLink>
			</div>
			<div
				className='mobile-nav-backdrop'
				onClick={() => navigationClose()}
			></div>
		</div>,
		document.getElementById("modal-root")
	)
}
