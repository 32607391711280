import ContactForm from "../../layaout/ContactForm/ContactForm"
import Business_Support from "../../layaout/Business_Support/Business_Support"
import "./Contact_Us.css"

export default function Contact_Us() {
	return (
		<div>
			<div className='contact-us-hero'>
				<h1 className='font-bold font-77 color-white '>Contact Us</h1>
			</div>
			<div className='l-height-100'></div>
			<div className='contact-us-page-container'>
				<div className='contact-us-page-first-part'>
					<h1 className='font-43 color-navy font-extrabold transparency'>
						Transparency
					</h1>
					<p className='font-19 l-height-30 color-text-secondary mt-20 transparency-description'>
						Our team of designers, developers, and project innovators are more
						than capable of working unaided, but we always push for a
						collaborative approach to transform businesses and make visions and
						goals into a reality.
					</p>
					<div className='mt-40'>
						<h2 className='font-37 text-center' style={{ color: "#2D2C2C" }}>
							Get Started
						</h2>
						<p
							className='font-semibold font-17 text-center l-height-24 mt-22'
							style={{
								maxWidth: "493px",
								width: "100%",
								marginLeft: "auto",
								marginRight: "auto",
								color: "#2D2C2C",
							}}
						>
							Call{" "}
							<span className='color-blue l-height-24'>(888) 717-4249</span> or
							fill out the form below to talk to us about your business.
						</p>
						<div className='mt-20'>
							<ContactForm boxShadow={true} />
						</div>
					</div>
				</div>
				<div className='contact-us-page-second-part'>
					<div className='contact-us-page-card'>
						<p className='font-22 font-bold color-navy'>Orbit Media Support</p>
						<div
							style={{
								width: "87px",
								height: "3px",
								background: "#FF0798",
								marginTop: "18px",
							}}
						></div>
						<p
							className='l-height-24 font-17 font-regular mt-15'
							style={{ color: "#2D2C2C" }}
						>
							Are you a current client with questions about your site?
						</p>
						<button className='meet-the-team-btn font-bold font-20 mt-20'>
							Meet The Team
						</button>
					</div>
					<div className='contact-us-page-card mt-35'>
						<p className='font-22 font-bold color-navy'>Visit Orbit</p>
						<div
							style={{
								width: "87px",
								height: "3px",
								background: "#FF0798",
								marginTop: "18px",
							}}
						></div>
						<p
							className='l-height-24 font-17 font-regular mt-15'
							style={{ color: "#2D2C2C" }}
						>
							4043 N Ravenswood Ave, #316 Chicago IL 60613 Map/Directions › Main
							(773) 348-4581
							<br />
							Support (773) 353-8314
						</p>

						<p className='font-22 font-bold color-navy mt-40'>
							Remittance Address
						</p>
						<div
							style={{
								width: "87px",
								height: "3px",
								background: "#FF0798",
								marginTop: "18px",
							}}
						></div>
						<p
							className='l-height-24 font-17 font-regular mt-20'
							style={{ color: "#2D2C2C" }}
						>
							1555 Sherman Ave, #302
							<br />
							Evanston, IL 60201.
						</p>
						<button className='meet-the-team-btn font-bold font-20 mt-20'>
							Meet The Team
						</button>
					</div>
				</div>
			</div>
			<div className='l-height-100'></div>
			<Business_Support />
		</div>
	)
}
