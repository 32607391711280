import "./Why_Outsource_Google_Ads.css"
import img1 from "./images/img-1.png"
import img2 from "./images/img-2.png"

export default function Why_Outsource_Google_Ads() {
	return (
		<div className='why-outsource-google-ads-wrapper row'>
			<div className='why-outsource-google-ads-first-img-text-container'>
				<img src={img1} />
				<div>
					<h1 className='color-navy font-39 font-bold l-height-50'>
						Why outsource Google Ads management to an SEM Agency?
					</h1>
					<p
						className='font-17 font-regular l-height-24 mt-25'
						style={{ color: "#2D2C2C" }}
					>
						There are many reasons, but we highlight 3<br />
						<br />
						1 Platforms change all the time, to be efficient in administration
						it is an essential condition to be 100% updated with respect to the
						latest strategies
						<br />
						<br />
						2 As a digital SEM agency, we invest in technology with the aim of
						improving all the KPI's of each campaign. Our approach based on
						highly trained personnel and optimization technology generates the
						best results for your campaigns.
						<br />
						<br />3 If you take into account the opportunity cost of working
						without an SEM agency, you will conclude that it is more profitable
						to work with a PREMIER Partner such as SEOnet. Isn't it better to
						invest your time in your business and in the tasks in which your
						company stands out?
					</p>
				</div>
			</div>
			<div className='why-outsource-google-ads-second-img-text-container'>
				<div>
					<h1 className='color-navy font-39 font-bold l-height-50'>
						Why outsource Google Ads management to an SEM Agency?
					</h1>
					<p
						className='font-17 font-regular l-height-24 mt-38'
						style={{ color: "#2D2C2C" }}
					>
						There are many reasons, but we highlight 3<br />
						<br />
						1 Platforms change all the time, to be efficient in administration
						it is an essential condition to be 100% updated with respect to the
						latest strategies
						<br />
						<br />
						2 As a digital SEM agency, we invest in technology with the aim of
						improving all the KPI's of each campaign. Our approach based on
						highly trained personnel and optimization technology generates the
						best results for your campaigns.
						<br />
						<br />3 If you take into account the opportunity cost of working
						without an SEM agency, you will conclude that it is more profitable
						to work with a PREMIER Partner such as SEOnet. Isn't it better to
						invest your time in your business and in the tasks in which your
						company stands out?
					</p>
				</div>
				<img src={img2} />
			</div>
		</div>
	)
}
