import React, { useState } from "react"
import "./Footer.css"
import twitter from "./images/twitter.svg"
import linkedin from "./images/linkedin.svg"
import youtube from "./images/youtube.svg"
import github from "./images/github.svg"
import logo from "./images/logo.png"
import upArrow from "./images/up-arrow.svg"
import downArrow from "./images/down-arrow.svg"
import { useNavigate } from "react-router-dom"

export default function Footer() {
	const [footerLinks, setFooterLinks] = useState([
		{
			id: 1,
			link: "Other Services",
			services: [
				{ name: "Google Ads", link: "google" },
				{ name: "Social Media Marketing", link: "social-media" },
			],
		},
		{
			id: 2,
			link: "Help & Support",
			services: [
				{ name: "About Us", link: "about-us" },
				{ name: "Contact Us", link: "contact" },
				{ name: "Privacy Policy" },
				{ name: "Terms & Condition" },
			],
		},
	])
	const [activeLinkId, setActiveLinkId] = useState(null)
	let navigate = useNavigate()
	return (
		<footer className='footer-wrapper'>
			<div className='row'>
				<div className='footer-desktop'>
					<div className='footer-links'>
						<div className='logo-part'>
							<img src={logo} className='logo' />
							<div className='footer-social-media-wrapper'>
								<div className='social-media-logo'>
									<img src={twitter} />
								</div>
								<div className='social-media-logo'>
									<img src={github} />
								</div>
								<div className='social-media-logo'>
									<img src={youtube} />
								</div>
								<div className='social-media-logo'>
									<img src={linkedin} />
								</div>
							</div>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-between",
							}}
							className='contact-us-wrapper'
						>
							<div className='footer-link-title-wrapper'>
								<p className='footer-link-title semibold-text'>Contact Us</p>
							</div>
							<div>
								<p className='service'>
									50 N. Laura St. Suite 2500 Jacksonville, FL 32202
								</p>
							</div>
							<div>
								<p className='service underline'>hello@kris.com</p>
							</div>
							<div>
								<p className='service'>904-472-111</p>
							</div>
						</div>
						{footerLinks.map(({ link, services }) => (
							<div>
								<div className='footer-link-title-wrapper'>
									<p className='footer-link-title semibold-text' key={link}>
										{link}
									</p>
								</div>
								<div>
									{services.map((service, index) => (
										<p
											className='service'
											key={index}
											onClick={() => {
												service.link && navigate(service.link)
											}}
										>
											{service.name}
										</p>
									))}
								</div>
							</div>
						))}
					</div>
				</div>

				<div className='footer-mobile'>
					<div className='logo-wrapper'>
						<img src={logo} className='logo' />
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
						}}
						className='contact-us-wrapper footer-link-wrapper'
						onClick={() =>
							activeLinkId === "contact"
								? setActiveLinkId(null)
								: setActiveLinkId("contact")
						}
					>
						<div
							className='footer-link-title-wrapper'
							style={{ marginBottom: 0 }}
						>
							<p className='footer-link-title semibold-text'>Contact Us</p>
							{activeLinkId === "contact" ? (
								<img src={upArrow} />
							) : (
								<img src={downArrow} />
							)}
						</div>

						{activeLinkId === "contact" && (
							<>
								<p className='service'>
									50 N. Laura St. Suite 2500 Jacksonville, FL 32202
								</p>

								<p className='service underline'>hello@kris.com</p>

								<p className='service'>904-472-111</p>
							</>
						)}
					</div>
					{footerLinks.map(({ link, services, id }) => (
						<div
							className='footer-link-wrapper'
							onClick={() =>
								activeLinkId === id
									? setActiveLinkId(null)
									: setActiveLinkId(id)
							}
						>
							<div className='footer-link-title-wrapper'>
								<p className='footer-link-title semibold-text' key={link}>
									{link}
								</p>
								{activeLinkId === id ? (
									<img src={upArrow} />
								) : (
									<img src={downArrow} />
								)}
							</div>
							{activeLinkId === id && (
								<div>
									{services.map((service, index) => (
										<p
											className='service'
											key={index}
											onClick={() => {
												service.link && navigate(service.link)
											}}
										>
											{service.name}
										</p>
									))}
								</div>
							)}
						</div>
					))}
					<div className='footer-social-media-wrapper'>
						<div className='social-media-logo'>
							<img src={twitter} />
						</div>
						<div className='social-media-logo'>
							<img src={github} />
						</div>
						<div className='social-media-logo'>
							<img src={youtube} />
						</div>
						<div className='social-media-logo'>
							<img src={linkedin} />
						</div>
					</div>
				</div>
				<p className='copyright text-center'>
					© All rights reserved BY high level 2022
				</p>
			</div>
		</footer>
	)
}
