import { useState } from "react"

import "./My_Google_Business.css"
import img from "./img.png"

export default function My_Google_Business() {
	const [showMore, setShowMore] = useState(false)
	return (
		<div className='row'>
			<div className='google-business-wrapper '>
				<div>
					<h1 className='font-57 color-navy google-business-title'>
						What is Google My
						<br /> Business?
					</h1>
					<p className='font-20 font-medium mt-43 l-height-30'>
						Google My Business is a FREE business listing that helps customers
						connect with you in Google Search and Google Maps. It’s a free tool
						that can help drive more website traffic and gain relatively easy
						sales and new customers. GMB is also a free mobile app, so you can
						download it and interact with local customers while you’re out and
						about.
					</p>
					{showMore && (
						<p className='font-20 font-medium l-height-30'>
							Your Google My Business listing is like a business directory
							listing with lots of cool features. It is free to use and run by
							Google, so improving your GMB listing also helps Google know more
							about your business and your business information with people who
							may be looking for a business such as yours. A GMB listing gives
							potential customers the option to call your phone number, send
							instant messages, visit your website, leave reviews and request
							driving directions if you have a physical location. You can also
							add news updates, or other info on events, products or services,
							and current special offers. In your Google My Business account,
							you will also see data such as how many people clicked on your
							website, how many people requested driving directions to your
							address and how people called your phone number.
						</p>
					)}
					<p
						className='font-20 font-bold mt-30'
						style={{ color: "#FF0798", cursor: "pointer" }}
						onClick={() => setShowMore((prev) => !prev)}
					>
						{showMore ? "Read Less" : "Read More"}
					</p>
				</div>
				<div className='google-business-img-container'>
					<img src={img} />
				</div>
			</div>
		</div>
	)
}
