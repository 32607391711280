import "./Home_Bg.css"

export default function Home_Bg() {
	return (
		<div className='home-bg-container'>
			<div className='home-bg-1 color-white font-43 text-center item-center'>
				<h1>I DON’T HAVE A WEBSITE AND I WANT TO ESTABLISH MYSELF ONLINE</h1>
			</div>
			<div className='home-bg-2 color-white font-43 text-center item-center'>
				<h1>I HAVE A WEBSITE AND I WANT TO BE MORE SUCCESSFUL ONLINE.</h1>
			</div>
		</div>
	)
}
