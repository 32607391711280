import img1 from "./images/img1.png"
import img2 from "./images/img2.png"
import img3 from "./images/img3.png"
import "./Better_Social_Media.css"

export default function Better_Social_Media() {
	return (
		<div className='row'>
			<h3
				className='font-30 font-bold l-height-700 text-center '
				style={{ color: "#213BAE" }}
			>
				Build a Better Social Media Presence
			</h3>
			<div
				className='grid-3 better_social_media_cards_container'
				style={{ gap: "31px" }}
			>
				<div className='better_social_media_card'>
					<img src={img1} />
					<div>
						<h5
							className='font-23 l-height-29 mt-30 font-bold'
							style={{ color: "#1B2D9B" }}
						>
							Develop Brand Awarenesss
						</h5>
						<div
							style={{
								width: "34px",
								height: "4px",
								background: "#FF0798",
								marginTop: "14px",
							}}
						></div>
						<p className='l-height-24 font-regular color-grey mt-22'>
							Want to be social? We have got you covered with growth-driven
							strategies to expand your brand. Utilizing the best word-of-mouth
							and social media tactics to expand your customer base.
						</p>
					</div>
				</div>
				<div className='better_social_media_card'>
					<img src={img2} />
					<div>
						<h5
							className='font-23 l-height-29 mt-30 font-bold'
							style={{ color: "#1B2D9B" }}
						>
							Build Relationship
						</h5>
						<div
							style={{
								width: "34px",
								height: "4px",
								background: "#FF0798",
								marginTop: "14px",
							}}
						></div>
						<p className='l-height-24 font-regular color-grey mt-22'>
							Give your customers a reason to engage with your brand. Our
							experts are successfully reinforcing your brand promise to
							consumers to maximize the engagement rate. Create a lasting
							connection today!
						</p>
					</div>
				</div>
				<div className='better_social_media_card'>
					<img src={img3} />
					<div>
						<h5
							className='font-23 l-height-29 mt-30 font-bold'
							style={{ color: "#1B2D9B" }}
						>
							Increasing Website Exposure
						</h5>
						<div
							style={{
								width: "34px",
								height: "4px",
								background: "#FF0798",
								marginTop: "14px",
							}}
						></div>
						<p className='l-height-24 font-regular color-grey mt-22'>
							Want to draw potential leads to your website? Skyrocket your
							site’s online exposure with our effective strategic plan. We have
							the right magic formula for your brand.
						</p>
					</div>
				</div>
			</div>
			<div className='item-center mt-60'>
				<button className='portfolio-btn font-bold font-20'>
					View Our Portfolio
				</button>
			</div>
		</div>
	)
}
