import "./Seo.css"
import Seo_Hero from "./Seo_Hero/Seo_Hero"
import Looking_To_Attract_More from "./Looking_To_Attract_More/Looking_To_Attract_More"
import Local_Seo_Services_Help from "./Local_Seo_Services_Help/Local_Seo_Services_Help"
import Monthly_Seo_Services from "./Monthly_Seo_Services/Monthly_Seo_Services"
import FAQ from "../../layaout/FAQ/FAQ"
import Contact from "../../layaout/Contact/Contact"
import Local_Seo_Services_Designed from "./Local_Seo_Services_Designed/Local_Seo_Services_Designed"
import Top_Ranked_Local_Business from "./Top_Ranked_Local_Business/Top_Ranked_Local_Business"
import Services_We_Offer from "./Services_We_Offer/Services_We_Offer"
import Hire_Us_To_Climb from "./Hire_Us_To_Climb/Hire_Us_To_Climb"

const _Seo = ({ show, setShow }) => {
	return (
		<>
			<Seo_Hero />
			<Local_Seo_Services_Designed />
			<Looking_To_Attract_More />
			<div className='l-height-100'></div>
			<Local_Seo_Services_Help />
			<Top_Ranked_Local_Business />
			<Services_We_Offer />
			<Monthly_Seo_Services />
			<Hire_Us_To_Climb />
			<div className='l-height-100'></div>
			<FAQ />
			<div className='l-height-100'></div>
			<Contact />
		</>
	)
}
export default _Seo
