import "./Gmb_Management.css"
import img1 from "./images/img-1.png"
import img2 from "./images/img-2.png"
import img3 from "./images/img-3.png"
import img4 from "./images/img-4.png"

export default function Gmb_Management() {
	return (
		<div className='row'>
			<div>
				<div className='gmb-img-text-wrapper'>
					<div className='gmb-img-container'>
						<img src={img1} />
					</div>
					<div>
						<h1 className='font-39 l-height-50 font-bold color-navy'>
							A GMB Management Service to help rankings and beyond
						</h1>
						<p
							className='font-17 l-height-24 font-regular mt-30'
							style={{ color: "#2D2C2C" }}
						>
							Google My Business management services are specially designed to
							simultaneously improve your SEO and GMB page for overall improved
							search results. When you optimize your Google listing, you are not
							only targeting top ranking factors for your online presence, but
							also covering certain off-page optimization techniques that take
							place away from your website and are gaining heavy traction in
							today’s SEO playing field.
						</p>
					</div>
				</div>
				<div className='gmb-img-text-wrapper gmb-img-text-wrapper-second'>
					<div>
						<h1 className='font-39 l-height-50 font-bold color-navy'>
							Active reputation and relationship management
						</h1>
						<p
							className='font-17 l-height-24 font-regular mt-30'
							style={{ color: "#2D2C2C" }}
						>
							Google Reviews and Google Questions and Answers are two of the
							most powerful features, both of which merit special attention
							because of their prominent placement within your Google Profile.
							Exceed expectations by developing a custom review strategy that
							aligns with your business ethos and helps build brand loyalty
							while influencing searchers to become clients. Anticipate
							searchers’ needs by getting FAQ answers published even before
							potential customers have the chance to ask, so that not time is
							wasted. All those are the GMB optimization tricks we use to get
							your listing to the top.
						</p>
					</div>
					<div className='gmb-img-container'>
						<img src={img2} />
					</div>
				</div>
				<div className='gmb-img-text-wrapper gmb-img-text-wrapper-third'>
					<div className='gmb-img-container'>
						<img src={img3} />
					</div>
					<div>
						<h1 className='font-39 l-height-50 font-bold color-navy'>
							Posts and updates for increased engagement
						</h1>
						<p
							className='font-17 l-height-24 font-regular mt-30'
							style={{ color: "#2D2C2C" }}
						>
							Google Posts is a special feature within your Google listing which
							offers an opportunity for creating engaging posts with content on
							current specials, events, new products or services, etc. These
							posts will make your business stand out and offer a space for
							engaging with customers directly in the search results. A GMB
							management service specialists can work with you to create
							compelling content and put in all the right features and detail to
							not only get you more engagement, but more business as well.
						</p>
					</div>
				</div>
				<div className='gmb-img-text-wrapper gmb-img-text-wrapper-four'>
					<div>
						<h1 className='font-39 l-height-50 font-bold color-navy'>
							Monitoring and knocking out GMB competitor spam
						</h1>
						<p
							className='font-17 l-height-24 font-regular mt-30'
							style={{ color: "#2D2C2C" }}
						>
							Beyond standard GMB management you can also hire us as a Google My
							Business anti spam report and analysis partner. We review the
							Google Maps landscape regularly, checking for spam and tactics
							like keyword stuffing, fake addresses, virtual offices, multiple
							listings, and other bad practices that manipulates SEO results. We
							can help to eliminate non-fair competitors and open up new ranking
							opportunities for your own business. Brands that show up on
							Google’s local 3-pack get a whopping 70% boost in clicks over
							brands that don’t. Don’t let a spammer take your spot!
						</p>
					</div>
					<div className='gmb-img-container'>
						<img src={img4} />
					</div>
				</div>
			</div>
		</div>
	)
}
