import "./Brand_Challenges.css"
import img1 from "./images/img-1.png"
import img2 from "./images/img-2.png"
import img3 from "./images/img-3.png"
import img4 from "./images/img-4.png"
import img5 from "./images/img-5.png"
import img6 from "./images/img-6.png"
import img7 from "./images/img-7.png"
import img8 from "./images/img-8.png"

export default function Brand_Challenges() {
	return (
		<div className='row'>
			<h3
				className=' font-30 font-bold l-height-700 text-center brand_challenges_title'
				style={{ color: "#213BAE" }}
			>
				How High level can help solve your brand challenges
			</h3>
			<div className='brand_challenges_card_container'>
				<div className='brand_challenge_card'>
					<img src={img1} />
					<h4 className='color-navy font-24 text-center'>
						Social media management​
					</h4>
					<p className='l-height-24 mt-20 text-center'>
						Our social obsessed experts can manage your social platforms and
						will delve into your brand’s DNA to understand your tone of voice
						and how to best engage with your audiences.
					</p>
				</div>
				<div className='brand_challenge_card'>
					<img src={img2} />
					<h4 className='color-navy font-24 text-center'>
						Social <br />
						advertising​
					</h4>
					<p className='l-height-24 mt-20 text-center'>
						Social ads are an essential element of building your community and
						brand. Whether your goal is to drive sales or build awareness, our
						team devise targeted and creative social ad campaigns that outsmart
						the competition and deliver results.
					</p>
				</div>
				<div className='brand_challenge_card'>
					<img src={img3} />
					<h4 className='color-navy font-24 text-center'>
						Social <br /> campaigns​
					</h4>
					<p className='l-height-24 mt-20 text-center'>
						​If you want to engage your audience, social is essential.
						<br />
						We deliver cost efficient campaigns that cut through the noise.
					</p>
				</div>
				<div className='brand_challenge_card'>
					<img src={img4} />
					<h4 className='color-navy font-24 text-center'>
						Influencer <br />
						management​
					</h4>
					<p className='l-height-24 mt-20 text-center'>
						We’ll deliver a bespoke and effective campaign by identifying and
						engaging with key influencers for your brand.
						<br />
						Whether paid or organic we’ll devise a plan to meet your needs.
					</p>
				</div>
				<div className='brand_challenge_card'>
					<img src={img5} />
					<h4 className='color-navy font-24 text-center'>
						Strategy &<br /> consultancy
					</h4>
					<p className='l-height-24 mt-20 text-center'>
						Here at Truffle our team are adept at analysing your business needs
						to devise and implement the right strategy with the correct level of
						support. We will clearly outline learnings and recommendations to be
						seamlessly implemented.
					</p>
				</div>
				<div className='brand_challenge_card'>
					<img src={img6} />
					<h4 className='color-navy font-24 text-center'>
						Digital <br />
						campaigns
					</h4>
					<p className='l-height-24 mt-20 text-center'>
						Our media buying experts are obsessed with the ever evolving digital
						landscape.
						<br />
						They will use their expert knowledge to build dynamic campaigns that
						are continually optimised to ensure best performance.
					</p>
				</div>
				<div className='brand_challenge_card'>
					<img src={img7} />
					<h4 className='color-navy font-24 text-center'>
						Event
						<br /> management​
					</h4>
					<p className='l-height-24 mt-20 text-center'>
						Let Truffle’s dedicated team take the headache out of event planning
						and production for you.
						<br />
						Whether it’s live social at an event, launch parties or product
						launches we will take care of it for you!
					</p>
				</div>
				<div className='brand_challenge_card'>
					<img src={img8} />
					<h4 className='color-navy font-24 text-center'>
						Content <br />
						production​
					</h4>
					<p className='l-height-24 mt-20 text-center'>
						​Whether it’s a photo shoot, videography, graphic design or live
						posting, we’re here to manage your brand’s content production.
						<br />
						Our creative team create, edit and deliver content that weaves the
						story you want tell
					</p>
				</div>
			</div>
		</div>
	)
}
