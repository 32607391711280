import "./ContactForm.css"

export default function ContactForm({ boxShadow = false }) {
	return (
		<div
			className='contact-form-container'
			style={{
				boxShadow: boxShadow && " 0px 0px 36px rgba(0, 0, 0, 0.44)",
			}}
		>
			<div className='contact-form-title'>
				<h1 className='font-semibold'>Grow with the pros</h1>
			</div>
			<div className='contact-form-sub-title'>
				<p className='font-semibold'>Let us know about you to get started</p>
			</div>
			<form
				className='contact-form'
				action='https://formsubmit.co/Info@alphawebdesign.com.au'
				method='POST'
			>
				<div className='name-phone-container'>
					<input placeholder='Name' type='text' name='name' required />
					<input placeholder='Phone No' type='text' name='phone' />
				</div>
				<input placeholder='Email' type='email' name='email' required />
				<textarea
					placeholder='Tell us about your business...'
					rows='6'
					type='text'
					name='message'
					required
				></textarea>
				<input type='submit' className='submit-form-btn font-semibold' />
			</form>
			<div className='contact-no'>
				<p className='font-semibold'>
					Want to Know more? Give us call at <span>+8121231243</span>
				</p>
			</div>
		</div>
	)
}
