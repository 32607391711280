import "./We_Market.css"
import img from "./img.png"

export default function We_Market() {
	return (
		<div className='row'>
			<div className='we-market-img-text-wrapper'>
				<div>
					<h1 className='font-bold font-39 color-navy l-height-50'>
						We Market & Grow Your Business Like It’s Our Own
					</h1>
					<p className='mt-30 font-17 l-height-24' style={{ color: "#2D2C2C" }}>
						We understand many businesses waste significant resources on digital
						marketing that doesn’t lead to sales. We have a process driven by
						data, proven strategies and results.
						<br />
						<br />
						From SEO and Google ads to website development and Facebook ads, you
						get a playbook for targeting the right clients and customers online.
					</p>
					<div className='we-market-btn-container mt-30'>
						<button className='marketing-strategist-btn font-bold color-white font-16'>
							Talk To A Marketing Strategist
						</button>
						<button
							className='discover-more-btn font-bold font-16'
							style={{ padding: "10px", maxWidth: "300px", width: "100%" }}
						>
							Discover More
						</button>
					</div>
				</div>
				<div className='we-market-img-container'>
					<img src={img} />
				</div>
			</div>
		</div>
	)
}
