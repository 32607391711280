import "./We_Help_business.css"
import img1 from "./images/img-1.png"
import img2 from "./images/img-2.png"
import img3 from "./images/img-3.png"
import img4 from "./images/img-4.png"
import star from "./images/star.png"
import checkMark from "./images/check-mark.png"

export default function We_Help_business() {
	return (
		<div className='row'>
			<div className='we-help-business-container'>
				<div>
					<h1 className='font-38 color-navy l-height-50 font-bold'>
						We Help Service Business Owners Increase Call Volume & Lead Flow
					</h1>
					<div className='star-container mt-30'>
						<img src={star} />
						<img src={star} />
						<img src={star} />
						<img src={star} />
						<img src={star} />
					</div>
					<p
						className='mt-30 font-16 font-regular l-height-24'
						style={{ color: "#2D2C2C" }}
					>
						We help bring service business owners more business by attracting
						more clients and customers through our proven local SEO services and
						digital marketing campaigns.
						<br />
						<br />
						If you are looking for more calls and leas so you can focus on doing
						what you do best instead of focusing on the marketing, please give
						us a call today so we can discuss your path to success. We care
						about great local business and are looking forward to hearing from
						you!
					</p>

					<div className='all-services-container mt-30'>
						<div className='our-service'>
							<img src={checkMark} />
							<p>SEO</p>
						</div>
						<div className='our-service'>
							<img src={checkMark} />
							<p>Digital PR</p>
						</div>
						<div className='our-service'>
							<img src={checkMark} />
							<p>Paid Advertising</p>
						</div>
						<div className='our-service'>
							<img src={checkMark} />
							<p>Social Media</p>
						</div>
						<div className='our-service'>
							<img src={checkMark} />
							<p>Google My Business</p>
						</div>
						<div className='our-service'>
							<img src={checkMark} />
							<p>Lead Generation</p>
						</div>
						<div className='our-service'>
							<img src={checkMark} />
							<p>Web Design</p>
						</div>
						<div className='our-service'>
							<img src={checkMark} />
							<p>Content Creation</p>
						</div>
					</div>
				</div>
				<div className='we-help-business-card-container'>
					<div className='we-help-business-card'>
						<img src={img1} />
						<div className='mx-auto mt-35'>
							<h3 className='text-center color-navy font-24 font-bold l-height-29'>
								Get More
							</h3>
							<h3 className='text-center color-navy font-24 font-bold l-height-29'>
								Weekly
							</h3>
							<h3 className='text-center color-navy font-24 font-bold l-height-29'>
								Calls with SEO
							</h3>
						</div>
					</div>
					<div className='we-help-business-card'>
						<img src={img2} />

						<div className='mx-auto mt-35'>
							<h3 className='text-center color-navy font-24 font-bold l-height-29'>
								{" "}
								Get Leads Fast
							</h3>
							<h3 className='text-center color-navy font-24 font-bold l-height-29'>
								with Paid
							</h3>
							<h3 className='text-center color-navy font-24 font-bold l-height-29'>
								Advertising
							</h3>
						</div>
					</div>
					<div className='we-help-business-card'>
						<img src={img3} />
						<div className='mx-auto mt-35'>
							<h3 className='text-center color-navy font-24 font-bold l-height-29'>
								{" "}
								Convert Sales
							</h3>
							<h3 className='text-center color-navy font-24 font-bold l-height-29'>
								with Full Web
							</h3>
							<h3 className='text-center color-navy font-24 font-bold l-height-29'>
								Design
							</h3>
						</div>
					</div>
					<div className='we-help-business-card'>
						<img src={img4} />

						<div className='mx-auto mt-35'>
							<h3 className='text-center color-navy font-24 font-bold l-height-29'>
								{" "}
								Spread The
							</h3>
							<h3 className='text-center color-navy font-24 font-bold l-height-29'>
								Word Through
							</h3>
							<h3 className='text-center color-navy font-24 font-bold l-height-29'>
								Digital PR
							</h3>
						</div>
					</div>
				</div>
			</div>
			<div>
				<button className='discover-more-btn font-20 font-bold mt-30'>
					Discover More
				</button>
			</div>
		</div>
	)
}
