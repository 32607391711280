import "./Not_Enough.css"

export default function Not_Enough() {
	return (
		<div className='row'>
			<div className='not-enough-wrapper'>
				<div>
					<h1 className='font-57 color-navy l-height-62'>
						It's not enough just to look good
					</h1>
					<p className='font-17 l-height-25 mt-30' style={{ color: "#2D2C2C" }}>
						A nice design with a strong visual identity is undoubtedly important
						for a professional website. It is about standing out from the crowd
						when the nearest competitor is only a click away. But what lies
						under the "hood" and the way it is all put together has a great
						influence on the results a company gets from its website.
						<br /> alpha marketing specializes in web design that is both
						beautiful and optimized to perform at its best. The goal is a more
						visible and effective website, which contributes to your company
						being able to create a better business.
					</p>
				</div>
				<div className='mt-20'>
					<p className='font-17 l-height-25'>
						Whether it's to improve your current website or if you need to start
						a project from scratch, our web design, development and digital
						marketing team is ready to help.
					</p>
					<ul
						className='font-regular font-22 mt-20'
						style={{ padding: " 0 20px", color: "#2D2C2C" }}
					>
						<li>Optimized for search engines.</li>
						<li className='mt-5'>Simple content management.</li>
						<li className='mt-5'>Design adapted to all devices.</li>
						<li className='mt-5'>Conversion oriented.</li>
						<li className='mt-5'>Integration of social networks.</li>
						<li className='mt-5'>Adaptation to corporate identity.</li>
					</ul>
					<button className='start-your-design-btn mt-35 font-20 font-bold'>
						Start your Web Design
					</button>
				</div>
			</div>
		</div>
	)
}
