import "./What_We_Do.css"

export default function What_We_Do() {
	return (
		<div className='row'>
			<div className='what-we-do-wrapper'>
				<h1 className='font-37 text-center font-extrabold l-height-40'>
					What We Do?
				</h1>
				<p
					className='font-17 l-height-30 font-semibold mt-20 text-center'
					style={{ color: "#2D2C2C" }}
				>
					As a full-service firm based in Bern, we pay close attention to every
					aspect that sets you apart from the competition. We place a premium on
					top quality in all of our service areas. No matter what the website is
					about? Whether it's a new website design, marketing initiatives based
					on an optimal online marketing plan, or minor website maintenance, our
					website designer staff can help.
				</p>
				<div className='grid-3 what-we-do-card-container mt-70'>
					<div className='what-we-do-card'>
						<h3 className='font-24 l-height-30'>Website Design</h3>
						<p
							className='font-15 l-height-24 mt-13'
							style={{ color: "#4B4F58", paddingBottom: "15px" }}
						>
							We will develop a professional website for you that is responsive
							and has a durable design for the online world. Website development
							will completely personalize it to your needs and ideas.
						</p>
						<button className='what-we-do-card-btn font-bold font-20'>
							More Details
						</button>
					</div>
					<div className='what-we-do-card'>
						<h3 className='font-24 l-height-30'>E-Commerce Website</h3>
						<p
							className='font-15 l-height-24 mt-13'
							style={{ color: "#4B4F58", paddingBottom: "15px" }}
						>
							We construct a sophisticated e-commerce website with automatic
							invoice processing so that you may profitably sell your products
							online. Creating an ecommerce website with us will definitely
							boost up your ecommerce products selling.
						</p>
						<button className='what-we-do-card-btn font-bold font-20'>
							More Details
						</button>
					</div>
					<div className='what-we-do-card'>
						<h3 className='font-24 l-height-30'>Landing Page</h3>
						<p
							className='font-15 l-height-24 mt-13'
							style={{ color: "#4B4F58", paddingBottom: "15px" }}
						>
							Focus Agency WebDesign developers can create the ideal landing
							page for you, increasing your conversion rate and generating more
							leads. We make the most out of your advertising and online
							marketing presence by using optimal design.
						</p>
						<button className='what-we-do-card-btn font-bold font-20'>
							More Details
						</button>
					</div>
					<div className='what-we-do-card'>
						<h3 className='font-24 l-height-30'>SEO</h3>
						<p
							className='font-15 l-height-24 mt-13'
							style={{ color: "#4B4F58", paddingBottom: "15px" }}
						>
							As an online marketing agency, we use sophisticated search engine
							optimization to boost your company's visibility in the search
							results.
						</p>
						<button className='what-we-do-card-btn font-bold font-20'>
							More Details
						</button>
					</div>
					<div className='what-we-do-card'>
						<h3 className='font-24 l-height-30'>Local SEO</h3>
						<p
							className='font-15 l-height-24 mt-13'
							style={{ color: "#4B4F58", paddingBottom: "15px" }}
						>
							Get the online attention you deserve. We use sponsored ads and SEO
							optimizing strategies to position you top in the search results
							utilizing well-planned search engine advertising.
						</p>
						<button className='what-we-do-card-btn font-bold font-20'>
							More Details
						</button>
					</div>
					<div className='what-we-do-card'>
						<h3 className='font-24 l-height-30'>Branding</h3>
						<p
							className='font-15 l-height-24 mt-13'
							style={{ color: "#4B4F58", paddingBottom: "15px" }}
						>
							From concept to completion of a website to the most effective
							marketing campaign, we are here to help. You may be confident that
							you will take the shortest route to your destination with our
							knowledge.
						</p>
						<button className='what-we-do-card-btn font-bold font-20'>
							More Details
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
