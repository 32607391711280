import "./Our_Services_Image_Grid.css"

export default function Our_Services_Image_Grid() {
	return (
		<>
			<div class='desktop-grid-containers'>
				<div class='grid-item-1 grid-item'>
					<h3 class='font-23'>Google Awards Marketing</h3>
					<p class='font-15 l-height-24 mt-10'>
						Google AdWords Set Up, Google agement, Google Remarketing, PPC
						Consultancy AdWords, Paid Social Man
					</p>
					<button class='more-details-btn font-bold font-17 mt-10'>
						More Deatils
					</button>
				</div>
				<div class='grid-item-2 grid-item'>
					<h3 class='font-23'>E-Commerce Website</h3>
					<p class='font-15 l-height-24 mt-10'>
						Google AdWords Set Up, Googleagement, Google Remarketing, PPC
						Consultancy AdWords, Paid Social Man
					</p>
					<button class='more-details-btn font-bold font-17 mt-10'>
						More Deatils
					</button>
				</div>
				<div class='grid-item-3 grid-item'>
					<h3 class='font-23'>Google Awards Marketing</h3>
					<p class='font-15 l-height-24 mt-10'>
						Google AdWords Set Up, Google agement, Google Remarketing, PPC
						Consultancy AdWords, Paid Social Man
					</p>
					<button class='more-details-btn font-bold font-17 mt-10'>
						More Deatils
					</button>
				</div>
				<div class='grid-item-4 grid-item'>
					<h3 class='font-23'>Google Awards Marketing</h3>
					<p class='font-15 l-height-24 mt-10'>
						Google AdWords Set Up, Google agement, Google Remarketing, PPC
						Consultancy AdWords, Paid Social Man
					</p>
					<button class='more-details-btn font-bold font-17 mt-10'>
						More Deatils
					</button>
				</div>
				<div class='grid-item-5 grid-item'>
					<h1 class='font-42 mt-82 text-center'>Our Services</h1>
				</div>
				<div class='grid-item-6 grid-item'>
					<h3 class='font-23'>Google Awards Marketing</h3>
					<p class='font-15 l-height-24 mt-10'>
						Google AdWords Set Up, Google agement, Google Remarketing, PPC
						Consultancy AdWords, Paid Social Man
					</p>
					<button class='more-details-btn font-bold font-17 mt-10'>
						More Deatils
					</button>
				</div>
			</div>
			<div class='mobile-grid-containers'>
				<div class='grid-item'>
					<h3 class='font-20 text-center'>Google Awards Marketing</h3>
					<p class='font-15 text-center mt-10'>
						Google AdWords Set Up, Google agement, Google Remarketing, PPC
						Consultancy AdWords, Paid Social Man
					</p>
					<div class='item-center'>
						<button class='more-details-btn font-bold font-17 mt-20'>
							More Deatils
						</button>
					</div>
				</div>
				<div class='grid-item'>
					<h3 class='font-20 text-center'>E-Commerce Website</h3>
					<p class='font-15 text-center mt-10'>
						Google AdWords Set Up, Googleagement, Google Remarketing, PPC
						Consultancy AdWords, Paid Social Man
					</p>
					<div class='item-center'>
						<button class='more-details-btn font-bold font-17 mt-20'>
							More Deatils
						</button>
					</div>
				</div>
				<div class='grid-item'>
					<h3 class='font-20 text-center'>Google Awards Marketing</h3>
					<p class='font-15 text-center mt-10'>
						Google AdWords Set Up, Google agement, Google Remarketing, PPC
						Consultancy AdWords, Paid Social Man
					</p>
					<div class='item-center'>
						<button class='more-details-btn font-bold font-17 mt-20'>
							More Deatils
						</button>
					</div>
				</div>
				<div class='grid-item'>
					<h3 class='font-20 text-center'>Google Awards Marketing</h3>
					<p class='font-15 text-center mt-10'>
						Google AdWords Set Up, Google agement, Google Remarketing, PPC
						Consultancy AdWords, Paid Social Man
					</p>
					<div class='item-center'>
						<button class='more-details-btn font-bold font-17 mt-20'>
							More Deatils
						</button>
					</div>
				</div>
				<div class='grid-item mobile-grid-item-with-img'>
					<h1 class='font-43 text-center'>Our Services</h1>
				</div>
				<div class='grid-item'>
					<h3 class='font-20 text-center'>Google Awards Marketing</h3>
					<p class='font-15 text-center mt-10'>
						Google AdWords Set Up, Google agement, Google Remarketing, PPC
						Consultancy AdWords, Paid Social Man
					</p>
					<div class='item-center'>
						<button class='more-details-btn font-bold font-17 mt-20'>
							More Deatils
						</button>
					</div>
				</div>
			</div>
		</>
	)
}
