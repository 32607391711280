import React from "react"
import Benefits_Of_Google from "./Benefits_Of_Google/Benefits_Of_Google"
import Gmb_Features_Benefits from "./Gmb_Features_Benefits/Gmb_Features_Benefits"
import Gmb_Management from "./Gmb_Management/Gmb_Management"
import Gmb_Management_Services from "./Gmb_Management_Services/Gmb_Management_Services"
import Google_My_Business_Hero from "./Google_My_Business_Hero/Google_My_Business_Hero"
import My_Google_Business from "./My_Google_Business/My_Google_Business"
import Whats_Included from "./Whats_Included/Whats_Included"
import FAQ from "../../layaout/FAQ/FAQ"
import Contact from "../../layaout/Contact/Contact"

export default function _Google_My_Business() {
	return (
		<>
			<Google_My_Business_Hero />
			<div className='l-height-120'></div>
			<My_Google_Business />
			<div className='height-66'></div>
			<Gmb_Features_Benefits />
			<div className='height-100'></div>
			<Gmb_Management />
			<div className='height-100'></div>
			<Gmb_Management_Services />
			<div className='height-100'></div>
			<Benefits_Of_Google />
			<div className='height-100'></div>
			<Whats_Included />
			<div className='height-100'></div>
			<FAQ />
			<div className='height-100'></div>
			<Contact />
		</>
	)
}
