import "./Local_Seo_Services_Help.css"
import img1 from "./img-1.png"
import img2 from "./img-2.png"
import img3 from "./img-3.png"
import img4 from "./img-4.png"
import img5 from "./img-5.png"

export default function Local_Seo_Services_Help() {
	return (
		<>
			<h1 className='Local_Seo_Services_Title text-center font-57 color-navy'>
				How Local SEO Services Help Your Business Grow?
			</h1>
			<p
				className='Local_Seo_Services_Paragraph text-center font-17 l-height-24 font-regular mt-30'
				style={{ color: "#2D2C2C" }}
			>
				Smartphones have revolutionized the way consumers find service
				providers. Almost 50% of online search queries are for products or
				services located nearest to the users. What does this mean for your
				business? It means you have the opportunity to showcase your business to
				the right people and increase your revenue in double-quick time.
			</p>
			<div className='l-height-100'></div>
			<p
				className='With_Local_Seo font-30 text-center font-semibold mb-50'
				style={{ color: "#030607" }}
			>
				With Local SEO on your side you can expect
			</p>
			<div class='timeline-container-desktop'>
				<div class='timeline-container'>
					<div class='timeline-item'>
						<div class='timeline-content-container'>
							<h2 class='l-height-30 font-bold font-23'>
								Increased Online Visibility for Local Consumers
							</h2>
							<p class='mt-20 l-height-24 timeline-content-paragraph font-15'>
								The trick to getting more local sales online is by showing up
								first on those search results. This can only happen if your
								Local SEO has been fine-tuned for optimum performance. With
								increased visibility and a search engine strategy that is
								optimized for your geographic area, you can always expect more
								lead and more sales.
							</p>
						</div>
						<div class='desktop-timeline-dot-horizontal-vertical-line-container'>
							<div class='timeline-dot'></div>
							<div class='desktop-timeline-vertical-line'></div>
							<div class='timeline-horizontal-line'></div>
						</div>
						<div class='timeline-img-container align-center'>
							<img src={img1} />
						</div>
					</div>
					<div class='timeline-item'>
						<div class='timeline-img-container justify-end align-center'>
							<img src={img2} />
						</div>
						<div class='desktop-timeline-dot-horizontal-vertical-line-container'>
							<div class='timeline-dot'></div>
							<div class='desktop-timeline-vertical-line'></div>
							<div class='timeline-horizontal-line'></div>
						</div>
						<div class='timeline-content-container'>
							<h2 class='l-height-30 font-bold font-23'>
								Increased Online Visibility for Local Consumers
							</h2>
							<p class='mt-20 l-height-24 timeline-content-paragraph font-15'>
								The trick to getting more local sales online is by showing up
								first on those search results. This can only happen if your
								Local SEO has been fine-tuned for optimum performance. With
								increased visibility and a search engine strategy that is
								optimized for your geographic area, you can always expect more
								lead and more sales.
							</p>
						</div>
					</div>
					<div class='timeline-item'>
						<div class='timeline-content-container'>
							<h2 class='l-height-30 font-bold font-23'>
								Increased Online Visibility for Local Consumers
							</h2>
							<p class='mt-20 l-height-24 timeline-content-paragraph font-15'>
								The trick to getting more local sales online is by showing up
								first on those search results. This can only happen if your
								Local SEO has been fine-tuned for optimum performance. With
								increased visibility and a search engine strategy that is
								optimized for your geographic area, you can always expect more
								lead and more sales.
							</p>
						</div>
						<div class='desktop-timeline-dot-horizontal-vertical-line-container'>
							<div class='timeline-dot'></div>
							<div class='desktop-timeline-vertical-line'></div>
							<div class='timeline-horizontal-line'></div>
						</div>
						<div class='timeline-img-container align-center'>
							<img src={img3} />
						</div>
					</div>
					<div class='timeline-item'>
						<div class='timeline-img-container justify-end align-center'>
							<img src={img4} />
						</div>
						<div class='desktop-timeline-dot-horizontal-vertical-line-container'>
							<div class='timeline-dot'></div>
							<div class='desktop-timeline-vertical-line'></div>
							<div class='timeline-horizontal-line'></div>
						</div>
						<div class='timeline-content-container'>
							<h2 class='l-height-30 font-bold font-23'>
								Increased Online Visibility for Local Consumers
							</h2>
							<p class='mt-20 l-height-24 timeline-content-paragraph font-15'>
								The trick to getting more local sales online is by showing up
								first on those search results. This can only happen if your
								Local SEO has been fine-tuned for optimum performance. With
								increased visibility and a search engine strategy that is
								optimized for your geographic area, you can always expect more
								lead and more sales.
							</p>
						</div>
					</div>
					<div class='timeline-item'>
						<div class='timeline-content-container'>
							<h2 class='l-height-30 font-bold font-23'>
								Increased Online Visibility for Local Consumers
							</h2>
							<p class='mt-20 l-height-24 timeline-content-paragraph font-15'>
								The trick to getting more local sales online is by showing up
								first on those search results. This can only happen if your
								Local SEO has been fine-tuned for optimum performance. With
								increased visibility and a search engine strategy that is
								optimized for your geographic area, you can always expect more
								lead and more sales.
							</p>
						</div>
						<div class='desktop-timeline-dot-horizontal-vertical-line-container'>
							<div class='timeline-dot'></div>
							<div class='desktop-timeline-vertical-line'></div>
							<div class='timeline-horizontal-line'></div>
						</div>
						<div class='timeline-img-container align-center'>
							<img src={img5} />
						</div>
					</div>
				</div>
			</div>
			<div class='timeline-container-mobile'>
				<div class='mobile-timeline-item-container'>
					<div class='mobile-timeline-item'>
						<div class='mobile-timeline-dot-vertical-line-container'>
							<div class='mobile-timeline-dot'></div>
							<div class='mobile-timeline-vertical-line'></div>
						</div>
						<div class='mobile-timeline-icon-text-container'>
							<div class='timeline-mobile-img-container'>
								<img src={img1} />
							</div>
							<div class='timeline-mobile-content-container'>
								<h2 class='l-height-30 font-bold font-23'>
									Increased Online Visibility for Local Consumers
								</h2>
								<p class='mt-20 l-height-24 timeline-content-paragraph font-15'>
									The trick to getting more local sales online is by showing up
									first on those search results. This can only happen if your
									Local SEO has been fine-tuned for optimum performance. With
									increased visibility and a search engine strategy that is
									optimized for your geographic area, you can always expect more
									lead and more sales.
								</p>
							</div>
						</div>
					</div>
					<div class='mobile-timeline-item'>
						<div class='mobile-timeline-dot-vertical-line-container'>
							<div class='mobile-timeline-dot'></div>
							<div class='mobile-timeline-vertical-line'></div>
						</div>
						<div class='mobile-timeline-icon-text-container'>
							<div class='timeline-mobile-img-container'>
								<img src={img2} />
							</div>
							<div class='timeline-mobile-content-container'>
								<h2 class='l-height-30 font-bold font-23'>
									Increased Online Visibility for Local Consumers
								</h2>
								<p class='mt-20 l-height-24 timeline-content-paragraph font-15'>
									The trick to getting more local sales online is by showing up
									first on those search results. This can only happen if your
									Local SEO has been fine-tuned for optimum performance. With
									increased visibility and a search engine strategy that is
									optimized for your geographic area, you can always expect more
									lead and more sales.
								</p>
							</div>
						</div>
					</div>
					<div class='mobile-timeline-item'>
						<div class='mobile-timeline-dot-vertical-line-container'>
							<div class='mobile-timeline-dot'></div>
							<div class='mobile-timeline-vertical-line'></div>
						</div>
						<div class='mobile-timeline-icon-text-container'>
							<div class='timeline-mobile-img-container'>
								<img src={img3} />
							</div>
							<div class='timeline-mobile-content-container'>
								<h2 class='l-height-30 font-bold font-23'>
									Increased Online Visibility for Local Consumers
								</h2>
								<p class='mt-20 l-height-24 timeline-content-paragraph font-15'>
									The trick to getting more local sales online is by showing up
									first on those search results. This can only happen if your
									Local SEO has been fine-tuned for optimum performance. With
									increased visibility and a search engine strategy that is
									optimized for your geographic area, you can always expect more
									lead and more sales.
								</p>
							</div>
						</div>
					</div>
					<div class='mobile-timeline-item'>
						<div class='mobile-timeline-dot-vertical-line-container'>
							<div class='mobile-timeline-dot'></div>
							<div class='mobile-timeline-vertical-line'></div>
						</div>
						<div class='mobile-timeline-icon-text-container'>
							<div class='timeline-mobile-img-container'>
								<img src={img4} />
							</div>
							<div class='timeline-mobile-content-container'>
								<h2 class='l-height-30 font-bold font-23'>
									Increased Online Visibility for Local Consumers
								</h2>
								<p class='mt-20 l-height-24 timeline-content-paragraph font-15'>
									The trick to getting more local sales online is by showing up
									first on those search results. This can only happen if your
									Local SEO has been fine-tuned for optimum performance. With
									increased visibility and a search engine strategy that is
									optimized for your geographic area, you can always expect more
									lead and more sales.
								</p>
							</div>
						</div>
					</div>
					<div class='mobile-timeline-item'>
						<div class='mobile-timeline-dot-vertical-line-container'>
							<div class='mobile-timeline-dot'></div>
							<div class='mobile-timeline-vertical-line'></div>
						</div>
						<div class='mobile-timeline-icon-text-container'>
							<div class='timeline-mobile-img-container'>
								<img src={img5} />
							</div>
							<div class='timeline-mobile-content-container'>
								<h2 class='l-height-30 font-bold font-23'>
									Increased Online Visibility for Local Consumers
								</h2>
								<p class='mt-20 l-height-24 timeline-content-paragraph font-15'>
									The trick to getting more local sales online is by showing up
									first on those search results. This can only happen if your
									Local SEO has been fine-tuned for optimum performance. With
									increased visibility and a search engine strategy that is
									optimized for your geographic area, you can always expect more
									lead and more sales.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='item-center mt-50'>
				<button className='local-seo-services-btn font-20 font-bold '>
					Start Your SEO
				</button>
			</div>
		</>
	)
}
