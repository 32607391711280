import Better_Social_Media from "./Better_Social_Media/Better_Social_Media"
import Brand_Challenges from "./Brand_Challenges/Brand_Challenges"
import Brand_Recognition from "./Brand_Recognition/Brand_Recognition"
import Social_Media_Hero from "./Social_Media_Hero/Social_Media_Hero"
import Social_Media_Marketing from "./Social_Media_Marketing/Social_Media_Marketing"
import Social_Media_Services from "./Social_Media_Services/Social_Media_Services"
import Contact from "../../layaout/Contact/Contact"
import FAQ_1 from "../../layaout/FAQ_1/FAQ_1"

export default function _Social_Media() {
	return (
		<>
			<Social_Media_Hero />
			<Social_Media_Services />
			<div className='l-height-120'></div>
			<Social_Media_Marketing />
			<div className='l-height-90'></div>
			<Brand_Challenges />
			<div className='l-height-130'></div>
			<Brand_Recognition />
			<div className='l-height-120'></div>
			<Better_Social_Media />
			<div className='l-height-120'></div>
			<FAQ_1 />
			<div className='l-height-120'></div>
			<Contact />
		</>
	)
}
