import "./Monthly_Seo_Services.css"

export default function Monthly_Seo_Services() {
	return (
		<>
			<div className='monthly-seo-services-wrapper'>
				<div className='row'>
					<h1 className='font-37 font-extrabold color-white l-height-40 text-center'>
						LOCAL SEO SERVICES: CAMPAIGN OVERVIEW
					</h1>
					<p
						className='font-regular font-16 color-white l-height-24 mt-20 text-center'
						style={{
							maxWidth: "1091px",
							width: "100%",
							marginLeft: "auto",
							marginRight: "auto",
						}}
					>
						Our team is happy to conduct a free website audit for you. We can
						determine your website's (content) and technical SEO status using
						this information.
					</p>
					<div
						className='monthly-seo-services-container grid-3 mt-60'
						style={{ gap: "60px" }}
					>
						<div className='monthly-seo-service'>
							<h1
								className='font-24 font-bold color-white l-height-43'
								style={{ height: "126px" }}
							>
								Market Analysis and Competitive Intelligence Deep Dive
							</h1>
							<p className='font-regular font-16 color-white l-height-24 mt-30'>
								Before we even begin, we conduct a sweeping review of your
								market, consumer behaviors, and competitive landscape in order
								to determine the best tactical approach to helping you get
								results fast
							</p>
						</div>
						<div className='monthly-seo-service'>
							<h1
								className='font-24 font-bold color-white l-height-43'
								style={{ height: "126px" }}
							>
								Transparent Performance Reporting
							</h1>
							<p className='font-regular font-16 color-white l-height-24 mt-30'>
								With Epidemic Marketing you always know what’s working and what
								isn’t. We believe in clear and transparent reporting that
								focuses on what matters: your KPIs. From incoming phone calls
								and inquiries, to lead generation, walk-ins and sales, we make
								sure you have the numbers you need to make the best decisions
								about your business
							</p>
						</div>
						<div className='monthly-seo-service'>
							<h1
								className='font-24 font-bold color-white l-height-43'
								style={{ height: "126px" }}
							>
								Google My Business <br />
								(GMB)
							</h1>
							<p className='font-regular font-16 color-white l-height-24 mt-30'>
								Arguably the most powerful lead generation tool in local SEO, we
								set up and optimize your GMB to consistently generate phone
								calls, inquiries and foot traffic. Ongoing optimization efforts
								focus on progressively ranking your GMB across your territory,
								dominating local maps and earning you more customers.
							</p>
						</div>
						<div className='monthly-seo-service'>
							<h1
								className='font-24 font-bold color-white l-height-43'
								style={{ height: "84px" }}
							>
								Content Planning, Creation and Publication
							</h1>
							<p className='font-regular font-16 color-white l-height-24 mt-30'>
								Optimized content is the food that fuels the Google beast,
								informing the search engine about how valuable your page(s)
								might be for any given keyword, keyword phrase or underlying
								search intent. Simply put, optimized content designed to exceed
								the needs and goals of the searcher means better rankings.
							</p>
						</div>
						<div className='monthly-seo-service'>
							<h1
								className='font-24 font-bold color-white l-height-43'
								style={{ height: "84px" }}
							>
								Local Citations
							</h1>
							<p className='font-regular font-16 color-white l-height-24 mt-30'>
								Local citations, directory listings, brand mentions, and more
								have a significant impact on local SEO. But even minor
								discrepancies between listings can be detrimental. We create,
								publish and fix key citations for your local market, making sure
								you have the right citations needed to outperform the
								competition.
							</p>
						</div>
						<div className='monthly-seo-service'>
							<h1
								className='font-24 font-bold color-white l-height-43'
								style={{ height: "84px" }}
							>
								Reviews and Reputation
							</h1>
							<p className='font-regular font-16 color-white l-height-24 mt-30'>
								Reviews and ratings are a major deciding factor for consumers
								who are determining where to spend their money. Some research
								shows that even one visible negative review can turn away up to
								22% of prospective new customers, with each additional negative
								review having a negative compounding effect.
							</p>
						</div>
					</div>
				</div>
			</div>
			{/* <div className='arrow-down'></div> */}
		</>
	)
}
