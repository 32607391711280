import "./Who_We_Are.css"
import img from "./img.png"
import plus from "./plus.png"

export default function Who_We_Are() {
	return (
		<div className='row'>
			<div className='who-we-are'>
				<div className='who-we-are-img-text-wrapper'>
					<div className='who-we-are-img-container'>
						<img src={img} />
					</div>
					<div>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div
								style={{
									width: "70px",
									height: "1.5px",
									background: "#030176",
								}}
							></div>
							<p
								style={{
									fontSize: "15px",
									marginLeft: "19px",
									color: "#FF0798",
								}}
								className='font-bold'
							>
								A team of Experts
							</p>
						</div>

						<h3 className='font-30 color-navy l-height-36 font-bold mt-7'>
							Who We Are
						</h3>
						<p className='mt-15 l-height-24 font-regular'>
							We are a team of expert digital marketers, search engine
							specialists, and web developers. We’re headquartered in Savannah,
							Georgia, but many of our team members work remotely. We are
							focused on teamwork and practice full transparency during our
							daily team meeting, hopping on “quick calls” throughout the rest
							of our days to ensure everyone is on the same page.
							<br />
							<br /> While we’re serious about digital marketing, we never take
							ourselves too seriously. When you work with FirstPage, you get
							both a Partner and down-to-earth Biz BFF.
						</p>
						<p className='mt-25 l-height-24 font-regular'>
							When you choose FirstPage you can be sure of three things:
						</p>
						<div className='mt-30'>
							<div>
								<div className='who-we-are-plus-text-container font-regular'>
									<img src={plus} />
									<p>We'll use 100% white-hat organic SEO techniques</p>
								</div>
							</div>
							<div className='mt-15'>
								<div className='who-we-are-plus-text-container font-regular'>
									<img src={plus} />
									<p>
										{" "}
										You'll get search engine specialists managing your campaign
									</p>
								</div>
							</div>
							<div className='mt-15'>
								<div className='who-we-are-plus-text-container font-regular'>
									<img src={plus} />
									<p>
										Those experts will be in-house (vetted and hired by
										FirstPage)
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
