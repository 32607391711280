import Take_Your_Business from "./Take_Your_Business/Take_Your_Business"
import Not_Enough from "./Not_Enough/Not_Enough"
import Website_Constructor from "./Website_Constructor/Website_Constructor"
import "./Web_Design.css"
import Web_Design_Hero from "./Web_Design_Hero/Web_Design_Hero"
import What_We_Do from "./What_We_Do/What_We_Do"
import Helping_Business from "../../layaout/Helping_Business/Helping_Business"
import FAQ_1 from "../../layaout/FAQ_1/FAQ_1"
import Why_Choose_Us from "./Why_Choose_Us/Why_Choose_Us"
import Contact from "../../layaout/Contact/Contact"

export default function Web_Design() {
	return (
		<div>
			<Web_Design_Hero />
			<div className='l-height-120'></div>
			<Not_Enough />
			<div className='l-height-120'></div>
			<Website_Constructor />
			<div className='l-height-120'></div>
			<What_We_Do />
			<div className='l-height-120'></div>
			<Take_Your_Business />
			<div className='l-height-120'></div>
			<Why_Choose_Us />
			<div className='l-height-120'></div>
			<Helping_Business />
			<div className='l-height-120'></div>
			<FAQ_1 />
			<div className='l-height-120'></div>
			<Contact />
		</div>
	)
}
