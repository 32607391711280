import "./Brand_Recognition.css"
import img1 from "./images/img1.png"
import img2 from "./images/img2.png"

export default function Brand_Recognition() {
	return (
		<div className='row'>
			<div className='brand_recognition_wrapper'>
				<div className='home_services_company_container'>
					<div>
						<h3 className='font-30 l-height-36' style={{ color: "#213BAE" }}>
							We offer efficient social media marketing services to support the
							expansion of your home service company.
						</h3>
						<p className='l-height-24 font-16 mt-24'>
							Recent studies show that the majority of Americans have at least
							one social media profile. We utilise a variety of sites, such as
							Facebook, Twitter, Instagram, and others. Sharing images and
							videos of your children on social media may be a powerful strategy
							for digital marketing. Your social media presence expands with
							Webfor beyond the occasional post. You will communicate directly
							with clients and your company will access unexplored markets. By
							enjoying, sharing, evaluating, and labelling your content, your
							users turn into your marketers. personal
						</p>
					</div>

					<img src={img1} />
				</div>
				<div className='brand_recognition_container'>
					<img src={img2} />
					<div>
						<h3 className='font-30 l-height-36' style={{ color: "#213BAE" }}>
							Increase Brand Recognition Through Social Media Marketing
						</h3>
						<p className='mt-35 font-16 l-height-24'>
							Regardless of the changes, it still requires a full-time
							commitment to make social media successful for your company.
							Facebook has become increasingly important to businesses as a way
							to connect with customers and stay in touch with friends and
							family. 
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}
