import "./Gmb_Features_Benefits.css"
import img1 from "./images/img-1.png"
import img2 from "./images/img-2.png"
import img3 from "./images/img-3.png"
import img4 from "./images/img-4.png"
import img5 from "./images/img-5.png"
import img6 from "./images/img-6.png"
import img7 from "./images/img-7.png"

export default function Gmb_Features_Benefits() {
	return (
		<div className='row gmb_features_container'>
			<div>
				<h3 className='color-navy font-57 font-bold l-height-700 text-center brand_challenges_title'>
					GMB Features & Benefits
				</h3>
				<div className='brand_challenges_card_container'>
					<div className='brand_challenge_card'>
						<img src={img1} />
						<h4 className='color-navy font-24 text-center mt-30'>
							IMPROVE LOCAL RANKINGS
						</h4>
						<p className='l-height-27 mt-20 text-center font-16'>
							Did you know GMB engagement signals— including reviews and
							Google’s Q&A—directly influence your online reputation, local
							rankings and overall sales? Watch your local rankings skyrocket
							with the right strategy!
						</p>
					</div>
					<div className='brand_challenge_card'>
						<img src={img2} />
						<h4 className='color-navy font-24 text-center mt-30'>
							PROTECT BRAND <br />
							REPUTATION
						</h4>
						<p className='l-height-27 mt-20 text-center font-16 '>
							Don't waste your valuable time replying to reviews and handling
							feedback. Let us take care of it for you. Digital Shift will
							manage your brand reputation, solidify customer relationships and
							eliminate buyerʼs remorse through implementing a personalized,
							on-brand reply strategy.
						</p>
					</div>
					<div className='brand_challenge_card'>
						<img src={img3} />
						<h4
							className='color-navy font-24 text-center'
							style={{ marginTop: "10px" }}
						>
							ENGAGE & CAPTIVATE
						</h4>
						<p className='l-height-27 mt-20 text-center font-16'>
							​If you want to engage your audience, social is essential.
							<br />
							We deliver cost efficient campaigns that cut through the noise.
						</p>
					</div>
					<div className='brand_challenge_card'>
						<img src={img4} />
						<h4 className='color-navy font-24 text-center mt-30'>
							ENGAGE & CAPTIVATE
						</h4>
						<p className='l-height-27 mt-20 text-center font-16'>
							Want to reach more customers? We'll spread the word about your
							business by sharing offers, blogs, products and photos. Get the
							most out of your Google My Business listing with consistent posts
							that connect with customers.
						</p>
					</div>
					<div className='brand_challenge_card'>
						<img src={img5} />
						<h4 className='color-navy font-24 text-center mt-30'>
							EXPERT
							<br /> OPTIMIZATION
						</h4>
						<p className='l-height-27 mt-20 text-center font-16'>
							You're missing out on leads because your listing isn't optimized
							right now. Weʼll give your company an edge by maximizing GMB
							features and expertly tailoring your listing. Partner with Digital
							Shift to outrank local competitors, spend less time on marketing
							efforts and be found by paying customers.
						</p>
					</div>
					<div className='brand_challenge_card'>
						<img src={img6} />
						<h4 className='color-navy font-24 text-center mt-30'>
							PRODUCTS & <br />
							SERVICES
						</h4>
						<p className='l-height-27 mt-20 text-center font-16'>
							Put your Google My Business to work! Do more with your listing by
							showcasing products and services to increase conversions. Get more
							customers and boost your bottom line while staying ahead of the
							competition.
						</p>
					</div>
					<div className='brand_challenge_card'>
						<img src={img7} />
						<h4 className='color-navy font-24 text-center mt-30'>
							MONITORING &<br /> SPAM FIGHTING
						</h4>
						<p className='l-height-27 mt-20 text-center font-16'>
							Weʼll work with Google to monitor and report GMB spam and policy
							violations, ensuring your competitors are playing fair and
							following Google's policies. Weʼll also audit and update user
							suggested and automated GMB changes to protect your listing. You
							won't have to worry about dealing with any unexpected headaches.
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}
