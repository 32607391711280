import Helping_Business from "../../layaout/Helping_Business/Helping_Business"
import Helping_Service_Business from "./Helping_Service_Business/Helping_Service_Business"
import Home_Bg from "./Home_Bg/Home_Bg"
import Home_Hero from "./Home_Hero/Home_Hero"
import Our_Services_Image_Grid from "./Our_Services_Image_Grid/Our_Services_Image_Grid"
import Web_Design from "./Web_Design/Web_Design"
import We_Help_business from "./We_Help_business/We_Help_business"
import We_Market from "./We_Market/We_Market"
import FAQ from "../../layaout/FAQ/FAQ"
import Contact from "../../layaout/Contact/Contact"
import "./Home.css"

export default function _Home() {
	return (
		<>
			<Home_Hero />
			<div className='mt-50'>
				<Web_Design />
			</div>
			<div className='l-height-200'></div>
			<We_Help_business />
			<div className='l-height-100'></div>
			<We_Market />
			<div className='l-height-100'></div>
			<Our_Services_Image_Grid />
			<div className='l-height-100'></div>
			<Helping_Service_Business />
			<div className='l-height-100'></div>
			<Helping_Business />
			<div className='l-height-100'></div>
			<Home_Bg />
			<div className='l-height-100'></div>
			<FAQ />
			<div className='l-height-100'></div>
			<Contact />
		</>
	)
}
