import "./Helping_Service_Business.css"
import img1 from "./images/img-1.png"
import img2 from "./images/img-2.png"
import img3 from "./images/img-3.png"
import img4 from "./images/img-4.png"

export default function Helping_Service_Business() {
	return (
		<div className='helping_business_wrapper'>
			<div className='helping_business_container row'>
				<div className='helping_business_card_container'>
					<div className='helping_business_card helping_business_card-1'>
						<img src={img1} />
						<p className='font-30 font-regular color-white mt-50'>
							Get More Weekly <br />
							Callls with SEO
						</p>
					</div>
					<div className='helping_business_card helping_business_card-2'>
						<img src={img2} />
						<p className='font-30 font-regular color-white mt-50'>
							Get Leads Fast With <br />
							Paiid Advertising
						</p>
					</div>
					<div className='helping_business_card helping_business_card-3'>
						<img src={img3} />
						<p className='font-30 font-regular color-white mt-50'>
							Get More Weekly Callls with SEO
						</p>
					</div>
					<div className='helping_business_card helping_business_card-4'>
						<img src={img4} />
						<p className='font-30 font-regular color-white mt-50'>
							Get More Weekly Callls with SEO
						</p>
					</div>
				</div>
				<div className='service-business-owner'>
					<div>
						<p className='font-16 color-white '>
							A RESULTS-DRIVEN DIGITAL MARKETING & SEO AGENCY
						</p>
						<h2
							className='font-39 color-white l-height-50 mt-10'
							style={{
								color: "rgba(255, 255, 255, 0.8)",
							}}
						>
							Helping Service Business Owners Increase Call Volume & Lead Flow
						</h2>
						<p
							className='color-white font-20 l-height-30 font-regular mt-30'
							style={{
								color: "rgba(255, 255, 255, 0.8)",
							}}
						>
							We help bring service business owners more business by attracting
							more clients and customers through our proven local SEO services
							and digital marketing campaigns. If you are looking for more calls
							and leads so you can focus on doing what you do best (instead of
							focusing on the marketing), please give us a call today so we can
							discuss your path to success. We care about great local business
							and are looking forward to hearing from you!
							<br />
							<br />
							Website design
							<br />
							Seo <br />
							Digital marketing
						</p>
					</div>
					<button className='service-business-owner-btn font-bold font-20 '>
						Explore More
					</button>
				</div>
			</div>
		</div>
	)
}
