import "./Seo_Hero.css"
import seo_img from "./img.png"

export default function Seo_Hero() {
	return (
		<div className='seo-wrapper'>
			{/* <div className='gutter-lg-local-seo'></div> */}
			<div className='seo-container row'>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<h1 className='seo-title font-77'>Local SEO</h1>
					<h1 className='font-77 color-white seo-services'>Services</h1>
					<p className='color-white font-17 l-height-24 mt-30 mb-25 font-medium'>
						Stop Missing Out On Qualified Traffic and Sales at a Fraction of the
						Cost of Paid Leads with Local SEO
					</p>
					<button className='book-a-call-btn font-25'>
						Book a Discovery Call With US
					</button>
					<div className='info-container color-white font-bold mt-20 font-22'>
						<span className='info-container-phone'>(323) 920-0350</span>
						<span className='info-container-email'>info@nahid.com</span>
					</div>
				</div>
				<div className='seo-img-container'>
					<img src={seo_img} />
				</div>
			</div>
			{/* <div className='gutter-lg-local-seo'></div> */}
		</div>
	)
}
