import "./Hire_Us_To_Climb.css"

export default function Hire_Us_To_Climb() {
	return (
		<div className='hire-us-to-climb-wrapper'>
			<h1 className='font-48 l-height-48 color-navy text-center hire-us-to-climb-title'>
				Hire Us to Climb The Search Engine Rankings.
			</h1>
			<p className='text-center mt-50 font-regular font-20 l-height-27 hire-us-to-climb-paragraph'>
				Truth be told, running a business is no joke. And if you happen to be in
				a super competitive niche your days will melt into nights without even
				realizing it. A Local SEO Company will share your load and give you the
				assurance of better business opportunities. At DWS we not only give you
				that assurance but also keep you involved in the journey every step of
				the way.
				<br /> <br />
				Our experienced SEO specialists understand the mysterious ways in which
				the web works. So be it an algorithm update from a search engine or a
				change in keyword vol
				<br />
				<br />A transparent working style and a hands-on approach that comes
				with experience, this is what you get when DWS takes care of your Local
				SEO requirements. With accurate competitor analysis, on-point
				strategies, and decades of knowledge, we ensure your business reaches
				new heights and revenue streams are always flowing.
			</p>
			<div className='item-center'>
				<button className='font-20 font-bold hire-us-to-climb-btn mt-25'>
					Request Free Qoute
				</button>
			</div>
		</div>
	)
}
