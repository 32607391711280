import "./Services_We_Offer.css"
import img from "./images/img.png"
import img1 from "./images/img-1.png"
import img2 from "./images/img-2.png"
import rightArrow from "./images/right-arrow.png"

export default function Services_We_Offer() {
	return (
		<div className='row'>
			<div className='width-1274'>
				<h1 className='font-37 l-height-40 text-center'>
					The Services We Offer Help Local Business Owners Get From No Traffic
					To Ranking High In Their Service Area In A Very Short Time!
				</h1>
				<div className='height-100'></div>
				<p
					className='font-18 l-height-30 text-center'
					style={{ color: "#2D2C2C" }}
				>
					Our SEO team improved a client's ranking in Chicago from the worst to
					#1 in just a few months. He did not receive any calls, leads, or
					traffic from his Google Business Profile listing when we first started
					working together. In other words, when it was "live" on Google, it
					didn't appear for the search terms he wanted. In our collaboration, we
					ranked him number one for his services in his area. Consequently, he
					now receives regular, organic inquiries about his business every week!
				</p>
				<div className='height-100'></div>
				<div className='services-we-offer-second-grid'>
					<img src={img1} />
					<img src={rightArrow} />
					<img src={img2} />
				</div>
				<div className='height-100'></div>
			</div>
		</div>
	)
}
