import "./Social_Media_Marketing.css"
import socialMediaObj from "./imgaes/social-media-obj.png"

export default function Social_Media_Marketing() {
	return (
		<div className='social_media_marketing_wrapper'>
			<div style={{ height: "50px" }}></div>
			<div className='social_media_container row'>
				<div className='social_media_image_container'>
					<img src={socialMediaObj} />
				</div>
				<div className='social_media_text_container'>
					<h3 className='font-35 font-bold l-height-50 color-navy'>
						How do our social media marketing services help you increase your
						online visibility?
					</h3>
					<p
						className='font-18 mt-18 l-height-27 font-regular'
						style={{ color: "#2D2C2C" }}
					>
						The two pillars of a successful social media marketing plan are
						quantity and quality. Webfor makes posts, graphics, and messages for
						particular social media networks. We can help you connect with the
						very distinct audiences on Facebook and Instagram.To grow and engage
						your audience on social media, you can employ both paid and organic
						(non-paid) techniques. This isn't just for sharing an image or some
						information about an event. We specialise in developing material
						that is suited to the requirements of a particular audience. We make
						an attempt to get in touch with influential people who can promote
						your message. 
					</p>
				</div>
			</div>
			<div style={{ height: "50px" }}></div>
		</div>
	)
}
