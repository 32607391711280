import "./Top_Ranked_Local_Business.css"
import seoImg from "./images/img.png"

export default function Top_Ranked_Local_Business() {
	return (
		<div className='top-ranked-local-business-container width-1274'>
			<img src={seoImg} />
			<div>
				<div style={{ display: "flex", alignItems: "center" }}>
					<div
						style={{
							width: "70px",
							height: "1.5px",
							background: "#030176",
						}}
					></div>
					<p
						style={{
							fontSize: "15px",
							marginLeft: "19px",
							color: "#FF0798",
						}}
						className='font-bold'
					>
						WE HELP LOCAL SERVICE BUSINESSES
					</p>
				</div>
				<h3
					className='font-30 font-bold l-height-36'
					style={{ color: "#213BAE" }}
				>
					Become The Top Ranked Local Business In Your Area
				</h3>
				<p className='font-regular font-16 l-height-24 mt-35'>
					Most business owners miss out on tons of traffic, leads, and new
					customers because they have not optimized their business for the
					online world.
					<span className='font-bold l-height-24'>
						That is where we come in.
					</span>
				</p>
				<div className='top-ranked-progress-section mt-60'>
					<div>
						<h2 className='font-bold font-20'>
							1. We Begin By Auditing Your Website & Business Profile
						</h2>
						<div className='top-ranked-progressbar-container'>
							<div className='top-ranked-progress top-ranked-progress-1'></div>
						</div>
					</div>
					<div className='mt-30'>
						<h2 className='font-bold font-20'>
							2. Then Perform Top-Tier SEO & GMB Optimization
						</h2>
						<div className='top-ranked-progressbar-container'>
							<div className='top-ranked-progress top-ranked-progress-2'></div>
						</div>
					</div>
					<div className='mt-30'>
						<h2 className='font-bold font-20'>
							3. You Enjoy The New Calls & Traffic Sent To Your Business!
						</h2>
						<div className='top-ranked-progressbar-container'>
							<div className='top-ranked-progress top-ranked-progress-3'></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
