import { BrowserRouter, Routes, Route } from "react-router-dom"
import Header1 from "./components/layaout/Header/Header"
import Footer from "./components/layaout/Footer/Footer"
import Home1 from "./components/Routes/Home1/_Home"
import Seo from "./components/Routes/Seo/_Seo"
import Google from "./components/Routes/Google/_Google"
import Social_Media from "./components/Routes/Social_Media/_Social_Media.js"
import Google_My_Business from "./components/Routes/Google_My_Business/_Google_My_Business"
import Local_Seo_Packages from "./components/Routes/Local_Seo_Packages/_Local_Seo_Packages"
import Web_Design from "./components/Routes/Web_Design/Web_Design"
import Increase_Sales from "./components/Routes/Increase_Sales/_Increase_Sales"
import Contact_Us from "./components/Routes/Contact_Us/Contact_Us"
import About_Us from "./components/Routes/About_Us/About_Us"

function App() {
	return (
		<div className='App'>
			<BrowserRouter>
				<Header1 />
				<Routes>
					<Route path='/' element={<Home1 />} exact />
					<Route path='/home1' element={<Home1 />} />
					<Route path='/seo' element={<Seo />} />
					<Route path='/web-design' element={<Web_Design />} />
					{/* <Route path='/increase-sales' element={<Increase_Sales />} /> */}
					<Route path='/google' element={<Google />} />
					<Route path='/about-us' element={<About_Us />} />
					<Route path='/contact' element={<Contact_Us />} />
					<Route path='/social-media' element={<Social_Media />} />
					<Route path='/Google_My_Business' element={<Google_My_Business />} />
					<Route path='/Local_Seo_Packages' element={<Local_Seo_Packages />} />
				</Routes>
				<Footer />
			</BrowserRouter>
		</div>
	)
}

export default App
