import "./Home_Hero.css"
import img from "./img.png"

export default function Home_Hero() {
	return (
		<div className='home-wrapper'>
			<div className='row home-container'>
				<div className='home-first-part'>
					<p className='color-white font-16 l-height-24 font-semibold'>
						Bullfrog Digital Marketing Agency & SEO Company
					</p>
					<div className='home-title-container'>
						<h1
							className='font-56 l-height-65'
							style={{ display: "inline-flex" }}
						>
							<span
								className='bg-white font-56 l-height-65'
								style={{ color: "#0129BB", display: "-webkit-inline-box" }}
							>
								Alpha
							</span>{" "}
							Digital
						</h1>
						<h1 className='font-56 l-height-65'>marketing agency</h1>
						<h1 className='font-56 l-height-65'>for HOME SERVICES </h1>
						<h1 className='font-56 l-height-65'>COMPANIES.</h1>
					</div>

					<p
						className='color-white font-16 l-height-24 mt-18 font-medium'
						style={{ color: "rgba(255, 255, 255, 0.8)" }}
					>
						Our goal is to help business owners achieve their goals through
						branding, marketing, and design. It's only a matter of time before
						your vision becomes a successful business.
					</p>
					<button className='book-a-call-btn font-25 mt-35'>
						Book a Discovery Call With US
					</button>
					<div className='info-container color-white font-bold mt-20 font-22'>
						<span className='info-container-phone'>(323) 920-0350</span>
						<span className='info-container-email'>info@nahid.com</span>
					</div>
				</div>
				<div className='home-second-part'>
					<img src={img} />
				</div>
			</div>
		</div>
	)
}
