import { useState } from "react"
import "./FAQ.css"

export default function FAQ() {
	const [faqs, setFaqs] = useState([
		{
			id: 1,
			question: "What is Twitter?",
			answer:
				"Twitter is a service for friends, family, and coworkers to communicate and stay connected through the exchange of quick, frequent messages. People post Tweets, which may contain photos, videos, links, and text. These messages are posted to your profile, sent to your followers, and are searchable on Twitter search. Learn more about how to use Twitter.",
		},
		{
			id: 2,
			question: "Do I need anything special to use it?",
			answer: `All you need to use Twitter is an internet connection or a mobile phone. Join us here! Once you're in, begin finding and following accounts whose Tweets interest you. We'll recommend great accounts once you're signed up.`,
		},
		{
			id: 3,
			question: "What's a Tweet?",
			answer: `A Tweet is any message posted to Twitter which may contain photos, videos, links, and text. Click or tap the Tweet button to post the update to your profile. Read our Posting a Tweet article for more information.`,
		},
		{
			id: 4,
			question: "How can I send updates to Twitter?",
			answer:
				"Read our article about how to post a Tweet. You can Tweet from twitter.com, a mobile device, or an application.",
		},
		{
			id: 5,
			question: `What's a Retweet?`,
			answer: "A Retweet is a Tweet that you forward to your followers.",
		},
		{
			id: 6,
			question: "What is Twitter?",
			answer:
				"Twitter is a service for friends, family, and coworkers to communicate and stay connected through the exchange of quick, frequent messages. People post Tweets, which may contain photos, videos, links, and text. These messages are posted to your profile, sent to your followers, and are searchable on Twitter search. Learn more about how to use Twitter.",
		},
		{
			id: 7,
			question: "Do I need anything special to use it?",
			answer: `All you need to use Twitter is an internet connection or a mobile phone. Join us here! Once you're in, begin finding and following accounts whose Tweets interest you. We'll recommend great accounts once you're signed up.`,
		},
		{
			id: 8,
			question: "What's a Tweet?",
			answer: `A Tweet is any message posted to Twitter which may contain photos, videos, links, and text. Click or tap the Tweet button to post the update to your profile. Read our Posting a Tweet article for more information.`,
		},
		{
			id: 9,
			question: "How can I send updates to Twitter?",
			answer:
				"Read our article about how to post a Tweet. You can Tweet from twitter.com, a mobile device, or an application.",
		},
		{
			id: 10,
			question: `What's a Retweet?`,
			answer: "A Retweet is a Tweet that you forward to your followers.",
		},
		{
			id: 11,
			question: "What is Twitter?",
			answer:
				"Twitter is a service for friends, family, and coworkers to communicate and stay connected through the exchange of quick, frequent messages. People post Tweets, which may contain photos, videos, links, and text. These messages are posted to your profile, sent to your followers, and are searchable on Twitter search. Learn more about how to use Twitter.",
		},
		{
			id: 12,
			question: "Do I need anything special to use it?",
			answer: `All you need to use Twitter is an internet connection or a mobile phone. Join us here! Once you're in, begin finding and following accounts whose Tweets interest you. We'll recommend great accounts once you're signed up.`,
		},
		{
			id: 13,
			question: "What's a Tweet?",
			answer: `A Tweet is any message posted to Twitter which may contain photos, videos, links, and text. Click or tap the Tweet button to post the update to your profile. Read our Posting a Tweet article for more information.`,
		},
		{
			id: 14,
			question: "How can I send updates to Twitter?",
			answer:
				"Read our article about how to post a Tweet. You can Tweet from twitter.com, a mobile device, or an application.",
		},
		{
			id: 15,
			question: `What's a Retweet?`,
			answer: "A Retweet is a Tweet that you forward to your followers.",
		},
	])
	const [activeFaqId, setActiveFaqId] = useState(null)

	return (
		<div className='faq-wrapper'>
			<div className='row'>
				<div>
					<h1 className=' text-center font-48 color-navy'>
						Frequently Asked Questions
					</h1>
					<p className='text-center l-height-27 font-medium font-20 mt-50'>
						We help bring service business owner more business by attracting
						more clients and customers through our proven local SEO services and
						digital marketing campaigns. We help bring service business owner
						more business by attracting more clients and customers through our
						proven local SEO services and digital marketing campaigns.
					</p>
					<div className='faq-container mt-50'>
						{faqs.map(({ id, question, answer }) => (
							<div key={id} className='faq'>
								<div className='faq-question-container'>
									<div className='faq-question'>
										<h3>{question}</h3>
									</div>
									{activeFaqId === id ? (
										<div
											className='faq-icon-container'
											onClick={() =>
												activeFaqId === id
													? setActiveFaqId(null)
													: setActiveFaqId(id)
											}
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												fill='none'
												viewBox='0 0 24 24'
												stroke-width='1.5'
												stroke='currentColor'
												class='w-6 h-6'
												onClick={() =>
													activeFaqId === id
														? setActiveFaqId(null)
														: setActiveFaqId(id)
												}
											>
												<path
													stroke-linecap='round'
													stroke-linejoin='round'
													d='M19.5 12h-15'
												/>
											</svg>
										</div>
									) : (
										<div
											className='faq-icon-container'
											onClick={() =>
												activeFaqId === id
													? setActiveFaqId(null)
													: setActiveFaqId(id)
											}
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												fill='none'
												viewBox='0 0 24 24'
												stroke-width='1.5'
												stroke='currentColor'
												class='w-6 h-6'
											>
												<path
													stroke-linecap='round'
													stroke-linejoin='round'
													d='M12 4.5v15m7.5-7.5h-15'
												/>
											</svg>
										</div>
									)}
								</div>
								{activeFaqId === id && <p className='faq-answer'>{answer}</p>}
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}
