import "./Social_Media_Hero.css"
import img from "./img.png"

export default function Social_Media_Hero() {
	return (
		<div className='social-media-wrapper'>
			<div className='gutter-lg-social-media'></div>
			<div className='social-media-container row'>
				<div className='social_media_first_part'>
					<h1 className='social-title font-77'>Social Media</h1>
					<h1 className='font-77 color-white marketing-title '>Marketing</h1>
					<p
						className='color-white font-17 l-height-24'
						style={{ marginTop: "4px" }}
					>
						By leveraging data, content, design, and strategy, we create a
						better customer experience To build trusted brands and loyal
						followers.
					</p>
					<button className='book-a-call-btn font-25 mt-43'>
						Book a Discovery Call With US
					</button>
					<div className='info-container color-white font-bold font-22 mt-20'>
						<span className='info-container-phone'>(323) 920-0350</span>
						<span className='info-container-email'>info@nahid.com</span>
					</div>
				</div>

				<img src={img} className='social_media_img' />
			</div>
			<div className='gutter-lg-social-media down'></div>
		</div>
	)
}
