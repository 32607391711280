import "./Web_Design.css"
import img1 from "./images/img-1.png"
import img2 from "./images/img-2.png"
import img3 from "./images/img-3.png"

export default function Web_Design() {
	return (
		<div className='row'>
			<div className='web-design-seo-container'>
				<h1 className='font-42 font-bold text-center color-navy'>
					Web Design & SEO Solutions
				</h1>
				<p
					className='font-medium font-24 l-height-39 text-center mx-auto mt-20'
					style={{
						color: "#030607",
						maxWidth: "858px",
						width: "100%",
						color: "#030607",
					}}
				>
					A team of experts specializes in search engine optimization, web
					development , google ads ,social media marketing and much more. We'll
					make your vision a reality today!
				</p>
				<div className='grid-3 mt-70 web-design-seo-card-containers'>
					<div className='web-design-seo-card'>
						<img src={img1} />

						<h3 className='text-center font-24 l-height-37 font-semibold mt-35'>
							Responsive & <br />
							Mobile Friendly
						</h3>
						<p className='text-center l-height-37 font-regular mt-12'>
							Your website will look great on any device. Our websites look
							incredible on computers, smartphones, and tablets alike.
						</p>
					</div>
					<div className='web-design-seo-card'>
						<img src={img2} />
						<h3 className='text-center font-24 l-height-37 font-semibold mt-35'>
							Optimized For <br />
							Search Rankings
						</h3>
						<p className='text-center l-height-37 font-regular mt-12'>
							Your website will look great on any device. Our websites look
							incredible on computers, smartphones, and tablets alike.
						</p>
					</div>
					<div className='web-design-seo-card'>
						<img src={img3} />
						<h3 className='text-center font-24 l-height-37 font-semibold mt-35'>
							Collect More <br />
							Sales Leads
						</h3>
						<p className='text-center l-height-37 font-regular mt-12'>
							Your website will look great on any device. Our websites look
							incredible on computers, smartphones, and tablets alike.
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}
