import "./Social_Media_Services.css"
import img1 from "./images/img-1.png"
import img2 from "./images/img-2.png"
import img3 from "./images/img-3.png"

export default function Social_Media_Services() {
	return (
		<div className='row'>
			<div className='social-media-services-wrapper'>
				<h3 className='social-media-services-title text-center font-30 l-height-36 font-bold'>
					Can help! Let’s go ahead with our social media services.
				</h3>
				<div className='social-media-services-paragraph font-17 l-height-24'>
					<p className='text-center'>
						Everyone these days is hunting for "brand culture," and we agree
						with its relevancy. If you are willing to build your brand with good
						culture, building a digital presence is your brand's lifeblood.
					</p>
					<p className='text-center'>
						If your brand's online presence makes you feel overwhelmed with
						complications and countless questions, kick start your digital
						journey with us. Our passionate and imaginatively gritty social
						media marketers will assist you in preserving against your brand's
						digital obstacles.
					</p>
				</div>
				<div className='social-media-services-logo-wrapper'>
					<div className='social-media-services-logo-container'>
						<img src={img1} />
						<h5 className='l-height-22 font-18 text-center mt-20'>
							Bring massive value to your brand.
						</h5>
					</div>
					<div className='social-media-services-logo-container'>
						<img src={img2} />
						<h5 className='l-height-22 font-18 text-center mt-20'>
							Bring massive value to your brand.
						</h5>
					</div>
					<div className='social-media-services-logo-container'>
						<img src={img3} />
						<h5 className='l-height-22 font-18 text-center mt-20'>
							Bring massive value to your brand.
						</h5>
					</div>
				</div>
			</div>
		</div>
	)
}
