import "./Grow_Your_Business.css"
import img from "./img.png"

export default function Grow_Your_Business() {
	return (
		<div className='row'>
			<div className='grow-business-wrapper'>
				<div>
					<h1 className='font-57 color-navy grow-business-title'>
						Grow Your Business Regionally
					</h1>
					<p className='font-30 font-bold mt-43 l-height-36 grow-business-subtitle'>
						Our local search engine optimization packages are best for small
						businesses with brick and mortar locations.
					</p>
					<p className='font-regular mt-20 l-height-30'>
						Our Local SEO packages are perfect for Small to Medium sized
						businesses looking to grow regionally. If you have less than 5
						locations and target a regional audience, one of our Local packages
						will fit. This package will help you increase your real estate on
						Google, increase web traffic, generate more leads and as a result
						Revenue will increase. SEO delivers one of the highest ROI of all
						marketing activities. Schedule a free consultation today to find out
						how our local SEO services can help increase your business’s website
						traffic, phone calls, walk-in traffic, and revenue.
					</p>
					<button className='grow-business-btn font-20 font-bold'>
						Request A FREE QOUTE
					</button>
				</div>
				<div className='grow-business-img-container'>
					<img src={img} />
				</div>
			</div>
		</div>
	)
}
