import "./Take_Your_Business.css"
import laptopIphone from "./laptop-iphone.png"
import laptopIphone1 from "./laptop-iphone-1.png"

export default function Take_Your_Business() {
	return (
		<div className='row'>
			<div className=' take-your-business-img-text-wrapper'>
				<div>
					<h1 className='font-bold font-39 color-navy l-height-50'>
						Take your business to the next level by standing out from the crowd
					</h1>
					<p className='mt-30 font-17 l-height-25'>
						Websites with our digital marketing and web design stand out from
						the crowd. Every business category is understood by us, and we
						always build websites that meet your needs. In addition, we create
						unique designs that can meet the needs of your business. Ultimately,
						it's your brand recognition and identity that will make your
						business stand out.
					</p>
				</div>
				<div className='take-your-business-img-container'>
					<img src={laptopIphone} />
				</div>
			</div>
			<div className='l-height-120'></div>
			<div className='take-your-business-img-text-wrapper'>
				<div className='take-your-business-img-container'>
					<img src={laptopIphone1} />
				</div>
				<div>
					<h1 className='font-bold font-39 color-navy l-height-50'>
						Take your business to the next level by standing out from the crowd
					</h1>
					<p className='mt-30 font-17 l-height-25'>
						Websites with our digital marketing and web design stand out from
						the crowd. Every business category is understood by us, and we
						always build websites that meet your needs. In addition, we create
						unique designs that can meet the needs of your business. Ultimately,
						it's your brand recognition and identity that will make your
						business stand out.
					</p>
				</div>
			</div>
		</div>
	)
}
