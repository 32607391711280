import Google_Hero from "./Google_Hero/Google_Hero"
import Agencys_secret from "./Agencys_secret/Agencys_secret"
import FAQ from "../../layaout/FAQ/FAQ"
import Contact from "../../layaout/Contact/Contact"
import Google_Advertising from "./Google_Advertising/Google_Advertising"
import Why_Outsource_Google_Ads from "./Why_Outsource_Google_Ads/Why_Outsource_Google_Ads"

const _Google = () => {
	return (
		<>
			<Google_Hero />
			<Google_Advertising />
			<Agencys_secret />
			<Why_Outsource_Google_Ads />
			<FAQ />
			<div className='height-100'></div>
			<Contact />
		</>
	)
}
export default _Google
