import "./Local_Seo_Services_Designed.css"
import img1 from "./images/img-1.png"
import img2 from "./images/img-2.png"
import img3 from "./images/img-3.png"

export default function Local_Seo_Services_Designed() {
	return (
		<div className='local-seo-services-designed-wrapper'>
			<div className='row local-seo-services-designed-container'>
				<div className='local-seo-services-designed-first-part'>
					<h1 className='font-57 l-height-63 color-navy font-bold local-seo-designed-title'>
						Local SEO Services Designed To Make Google Work For You!
					</h1>
					<ul
						className='font-20 font-regular mt-50 l-height-27'
						style={{
							padding: "0 20px",
							color: "#2D2C2C",
						}}
					>
						<li>
							You need to generate more calls and generate more leads from
							customers near your business, but…
						</li>
						<li className='mt-30'>
							You don’t know how to optimize local maps or get your business to
							rank in the Google Map Pack.
						</li>
						<li className='mt-30'>
							You are under a lot of pressure to generate more qualified leads,
							increase foot traffic, and generate more calls to your local
							business.
						</li>
						<li className='mt-30'>
							What if you could focus on your business and let us create,
							optimize, and execute your local SEO strategy.
						</li>
						<li className='mt-30'>
							Our local SEO consultants will build business citations, optimize
							local directories, and ensure that your business is featured on
							different business profiles like Google Business Profile, Bing,
							and Apple Maps.
						</li>
						<li className='mt-30'>
							Our local SEO services are made to fit your business objectives.
							We use proven local SEO tactics to generate more local leads,
							increase local searches, and help you increase qualified phone
							calls today!
						</li>
						<button className='local-seo-services-designed-btn font-20 font-bold '>
							{" "}
							Start your Local SEO
						</button>
					</ul>
				</div>
				<div className='local-seo-services-designed-card-container'>
					<div className='local-seo-services-designed-card'>
						<img src={img1} />
						<h2 className='font-bold font-24 l-height-29 color-navy mt-30 text-uppercase'>
							Local SEO Results Rank High In Local Search Results
						</h2>
						<p className='font-16 font-regular l-height-24 mt-20'>
							Harness the power of On-Page and Off-Page signals so search
							engines display your business for local searchers. These results
							rank above organic SEO results and can result in more sales.
						</p>
					</div>
					<div className='local-seo-services-designed-card mt-53'>
						<img src={img2} />
						<h2 className='font-bold font-24 l-height-29 color-navy mt-30 text-uppercase'>
							Increase Foot Traffic & Qualified Phone Calls
						</h2>
						<p className='font-16 font-regular l-height-24 mt-20'>
							More than 64% of local searches are looking for your business
							right in their own backyard. Over 50% of local customers who
							search for a local business will visit the store or office the
							next day!
						</p>
					</div>
					<div className='local-seo-services-designed-card mt-53'>
						<img src={img3} />
						<h2 className='font-bold font-24 l-height-29 color-navy mt-30 text-uppercase'>
							Capture More Mobile Traffic
						</h2>
						<p className='font-16 font-regular l-height-24 mt-20'>
							78% of mobile searches end with a purchase, and you can capture
							more market share by optimizing for local SEO. Increase your lead
							generation and conversion rate with local SEO tactics!
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}
