import { NavLink } from "react-router-dom"
import phoneIcon from "./phone.png"
import "./Business_Support.css"

export default function Business_Support() {
	let activeStyle = {
		textDecoration: "none",
		color: "#ffffff",
		fontSize: "17px",
		fontWeight: 700,
	}

	let inactiveStyle = {
		color: "#ffffff",
		textDecoration: "none",
		fontSize: "17px",
		fontWeight: 500,
	}
	return (
		<>
			<div className='business-support-wrapper'>
				<div className='business-support-container'>
					<h3 className='font-37 l-height-40 text-center font-bold business-title color-white'>
						Does your business need more business? We help local service
						business owners receive more calls, leads, and traffic.
					</h3>
					<p className='font-22 font-bold text-center fast-support mt-20'>
						Call us for fast support or book an appointment below.
					</p>
					<div className='item-center mt-50'>
						<img src={phoneIcon} />
						<h1 className='font-43 font-bold l-height-28 color-white'>
							01784856059
						</h1>
					</div>
					<div className='item-center mt-20'>
						<button className='book-a-call-btn font-25'>
							Book a Discovery Call With US
						</button>
					</div>
				</div>
			</div>
			{/* <div className='buisness-support-navbar-wrapper'>
				<div className='buisness-support-navbar-container'>
					<NavLink
						to='/home1'
						style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
					>
						{" "}
						Home{" "}
					</NavLink>

					<NavLink
						to='/seo'
						style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
					>
						{" "}
						SEO{" "}
					</NavLink>
					<NavLink
						to='/web-design'
						style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
					>
						{" "}
						Web Design{" "}
					</NavLink>

					<NavLink
						to='/google'
						style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
					>
						{" "}
						Google Ads{" "}
					</NavLink>
					<NavLink
						to='/aboutus'
						style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
					>
						{" "}
						About Us{" "}
					</NavLink>

					<NavLink
						to='/Local_Seo_Packages'
						style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
					>
						Contact
					</NavLink>
				</div>
			</div> */}
		</>
	)
}
