import img from "./img.png"
import "./Whats_Included.css"

export default function Whats_Included() {
	return (
		<div className='row'>
			<h1 className='font-57 text-center color-navy whats-included-title'>
				What’s’ Included Our GBP Management Services?
			</h1>
			<p
				className='font-17 font-regular text-center l-height-24 mt-30'
				style={{ color: "#2D2C2C" }}
			>
				Are you having difficulties ranking high in Google? We can help! We
				provide an “all-inclusive” marketing plan to successfully expand your
				GMB presence online. You can sleep easy knowing that you are backed by
				our proven Google Business Profile marketing experts! No matter if you
				need help managing a single location or several locations, we have
				knowledge and expertise to take your Google Business Profile listing to
				the next level.
			</p>
			<div className='mt-70 grid-3'>
				<div className='whats-included-grid-item'>
					<div className='whats-included-grid-item-img-container'>
						<img src={img} />
					</div>
					<div>
						<p className='font-bold font-20' style={{ color: "#2D2C2C" }}>
							Optimize Your GBP Listing
						</p>
						<p
							className='font-regular mt-24 l-height-24'
							style={{ color: "#2D2C2C" }}
						>
							The first step to a successful Google business account is to
							ensure it’s optimized from A-Z, & that is exactly what we will do
							for you!
						</p>
					</div>
				</div>
				<div className='whats-included-grid-item'>
					<div className='whats-included-grid-item-img-container'>
						<img src={img} />
					</div>
					<div>
						<p className='font-bold font-20' style={{ color: "#2D2C2C" }}>
							Content Posting
						</p>
						<p
							className='font-regular mt-24 l-height-24'
							style={{ color: "#2D2C2C" }}
						>
							Weekly Google Business Profile posting. Increase your brand
							awareness and expand your reach.
						</p>
					</div>
				</div>
				<div className='whats-included-grid-item'>
					<div className='whats-included-grid-item-img-container'>
						<img src={img} />
					</div>
					<div>
						<p className='font-bold font-20' style={{ color: "#2D2C2C" }}>
							Social Media Posting
						</p>
						<p
							className='font-regular mt-24 l-height-24'
							style={{ color: "#2D2C2C" }}
						>
							We will publish your GBP posts to your social accounts including
							Facebook, Instagram, Twitter, and more!
						</p>
					</div>
				</div>
				<div className='whats-included-grid-item'>
					<div className='whats-included-grid-item-img-container'>
						<img src={img} />
					</div>
					<div>
						<p className='font-bold font-20' style={{ color: "#2D2C2C" }}>
							Promotional Videos
						</p>
						<p
							className='font-regular mt-24 l-height-24'
							style={{ color: "#2D2C2C" }}
						>
							Have a special offer or want to promote your services? We will
							create a promotional video and post to your Google Business
							account!
						</p>
					</div>
				</div>
				<div className='whats-included-grid-item'>
					<div className='whats-included-grid-item-img-container'>
						<img src={img} />
					</div>
					<div>
						<p className='font-bold font-20' style={{ color: "#2D2C2C" }}>
							Listings Management
						</p>
						<p
							className='font-regular mt-24 l-height-24'
							style={{ color: "#2D2C2C" }}
						>
							Let’s leverage your locations listings to gain an edge on the
							competition. We will publish your business information to over 70
							directory sites.
						</p>
					</div>
				</div>
				<div className='whats-included-grid-item'>
					<div className='whats-included-grid-item-img-container'>
						<img src={img} />
					</div>
					<div>
						<p className='font-bold font-20' style={{ color: "#2D2C2C" }}>
							High Quality Images
						</p>
						<p
							className='font-regular mt-24 l-height-24'
							style={{ color: "#2D2C2C" }}
						>
							We include premium images with all posts or we can use yours, it’s
							up to you! High quality images boost profile engagement and we got
							you covered.
						</p>
					</div>
				</div>
				<div className='whats-included-grid-item'>
					<div className='whats-included-grid-item-img-container'>
						<img src={img} />
					</div>
					<div>
						<p className='font-bold font-20' style={{ color: "#2D2C2C" }}>
							Get More 5-Star Reviews
						</p>
						<p
							className='font-regular mt-24 l-height-24'
							style={{ color: "#2D2C2C" }}
						>
							Collecting positive reviews has never been easier. Get the latest
							technology and software to improve your reviews!
						</p>
					</div>
				</div>
				<div className='whats-included-grid-item'>
					<div className='whats-included-grid-item-img-container'>
						<img src={img} />
					</div>
					<div>
						<p className='font-bold font-20' style={{ color: "#2D2C2C" }}>
							Priority Support
						</p>
						<p
							className='font-regular mt-24 l-height-24'
							style={{ color: "#2D2C2C" }}
						>
							When you work with our agency you will be assigned a dedicated
							account manager. No ticket system, always speak to a human!
						</p>
					</div>
				</div>
				<div className='whats-included-grid-item'>
					<div className='whats-included-grid-item-img-container'>
						<img src={img} />
					</div>
					<div>
						<p className='font-bold font-20' style={{ color: "#2D2C2C" }}>
							Free Bonuses
						</p>
						<p
							className='font-regular mt-24 l-height-24'
							style={{ color: "#2D2C2C" }}
						>
							When you work with our agency you will be assigned a dedicated
							account manager. No ticket system, always speak to a human!
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}
