import "./Helping_Business.css"
import img1 from "./images/img-1.png"
import img2 from "./images/img-2.png"
import img3 from "./images/img-3.png"
import img4 from "./images/img-4.png"
import img5 from "./images/img-5.png"
import img6 from "./images/img-6.png"
import img7 from "./images/img-7.png"
import img8 from "./images/img-8.png"
import img9 from "./images/img-9.png"
import img10 from "./images/img-10.png"
import img11 from "./images/img-11.png"
import img12 from "./images/img-12.png"

export default function Helping_Business() {
	return (
		<div className='row'>
			<div className='Helping_Business_Container'>
				<div className='helping_business_content'>
					<p className='font-medium font-20'>Industries We Work For</p>
					<h1 className='font-48 font-bold l-height-62'>
						Helping Businesses In All Domains
					</h1>
					<p className='font-medium font-20 l-height-34 mt-20'>
						At Leading Edge, we believe in the power of inspired actions.
						Contact our custom web design and digital marketing champions to
						discuss your project.
					</p>
				</div>
				<div className='helping_business_domain_card_container'>
					<div className='helping_business_domain_card'>
						<img src={img1} />
						<p className='font-medium font-22 text-center'>Ecommerce</p>
					</div>
					<div className='helping_business_domain_card'>
						<img src={img2} />
						<p className='font-medium font-22 text-center'>Healthcare</p>
					</div>
					<div className='helping_business_domain_card'>
						<img src={img3} />
						<p className='font-medium font-22 text-center'>Travel</p>
					</div>
					<div className='helping_business_domain_card'>
						<img src={img4} />
						<p className='font-medium font-22 text-center'>Real Estate</p>
					</div>

					<div className='helping_business_domain_card'>
						<img src={img5} />
						<p className='font-medium font-22 text-center'>Fashion</p>
					</div>
					<div className='helping_business_domain_card'>
						<img src={img6} />
						<p className='font-medium font-22 text-center'>Dental</p>
					</div>
					<div className='helping_business_domain_card'>
						<img src={img7} />
						<p className='font-medium font-22 text-center'>Chiropractor</p>
					</div>
					<div className='helping_business_domain_card'>
						<img src={img8} />
						<p className='font-medium font-22 text-center'>BioTech</p>
					</div>

					<div className='helping_business_domain_card'>
						<img src={img9} />
						<p className='font-medium font-22 text-center'>Jewellery</p>
					</div>
					<div className='helping_business_domain_card'>
						<img src={img10} />
						<p className='font-medium font-22 text-center'>Fitness Trainer</p>
					</div>
					<div className='helping_business_domain_card'>
						<img src={img11} />
						<p className='font-medium font-22 text-center'>Restaurants</p>
					</div>
					<div className='helping_business_domain_card'>
						<img src={img12} />
						<p className='font-medium font-22 text-center'>Law Firm</p>
					</div>
				</div>
			</div>
		</div>
	)
}
