import "./Google_Hero.css"
import google_hero_img from "./images/google-hero-img.png"

export default function Google_Hero() {
	return (
		<div className='google-ads-wrapper'>
			{/* <div className='gutter-lg-google-ads'></div> */}
			<div className='google-ads-container row'>
				<div className='google_ads_first_part'>
					<h1 className='google-ads-title font-77'>Advertising on</h1>
					<h1 className='font-77 color-white google-ads-sub-title'>
						Google Ads
					</h1>
					<p className='color-white font-17 l-height-24 mt-15'>
						Experts can handle the management of your Google Ads campaign. We
						are certified Google Partner PREMIER. Our experience and technology
						guarantees the security of your investment
					</p>
					<button className='book-a-call-btn font-25 mt-30'>
						Book a Discovery Call With US
					</button>
					<div className='info-container color-white font-bold font-22 mt-30'>
						<span className='info-container-phone'>(323) 920-0350</span>
						<span className='info-container-email'>info@nahid.com</span>
					</div>
				</div>
				<div className='google-ads-second-part'>
					<img src={google_hero_img} />
				</div>
			</div>
			{/* <div className='gutter-lg-google-ads'></div> */}
		</div>
	)
}
