import { useState } from "react"
import "./Benefits_Of_Google.css"
import img from "./img.png"
import plus from "./plus.png"
import minus from "./minus.png"

export default function Benefits_Of_Google() {
	const [googleBenefits, setGoogleBenefits] = useState([
		{
			id: 1,
			question: "How Customers Search For Your Business",
			answer: `The practice to optimize your website for a specific local area
            is known as local SEO. Local SEO is most suitable for local
            businesses such as shops, restaurants, and agencies who want
            their web pages to rank for specific search queries performed by
            the local audiences in that area.`,
		},
		{
			id: 2,
			question: "Search and Maps Analysis",
			answer: `The practice to optimize your website for a specific local area
            is known as local SEO. Local SEO is most suitable for local
            businesses such as shops, restaurants, and agencies who want
            their web pages to rank for specific search queries performed by
            the local audiences in that area.`,
		},
		{
			id: 3,
			question: "View Customer Actions",
			answer: `The practice to optimize your website for a specific local area
            is known as local SEO. Local SEO is most suitable for local
            businesses such as shops, restaurants, and agencies who want
            their web pages to rank for specific search queries performed by
            the local audiences in that area.`,
		},
		{
			id: 4,
			question: "Local Direction Footprint",
			answer: `The practice to optimize your website for a specific local area
            is known as local SEO. Local SEO is most suitable for local
            businesses such as shops, restaurants, and agencies who want
            their web pages to rank for specific search queries performed by
            the local audiences in that area.`,
		},
		{
			id: 5,
			question: "Photo Views and Quantity",
			answer: `The practice to optimize your website for a specific local area
            is known as local SEO. Local SEO is most suitable for local
            businesses such as shops, restaurants, and agencies who want
            their web pages to rank for specific search queries performed by
            the local audiences in that area.`,
		},
	])
	const [activeGoogleBenefit, setActiveGoogleBenefit] = useState(null)

	return (
		<div className='row'>
			<div className='benefits-of-google-wrapper'>
				<h1 className='font-57 text-center color-navy gmb-management-services-title'>
					Benefits of Google My Business Management Services & Insights
				</h1>
				<p className='font-24 mt-30 l-height-30 text-center'>
					GMB Insights provides for some of the best reporting in a marketer's
					toolbox.
				</p>

				<div className='gmb-management-services-img-text-wrapper mt-60'>
					<div>
						{googleBenefits.map(({ id, question, answer }, index) => (
							<div
								className='benefit-of-google-question'
								key={id}
								onClick={() =>
									activeGoogleBenefit === id
										? setActiveGoogleBenefit(null)
										: setActiveGoogleBenefit(id)
								}
								style={{ marginTop: index != 0 && "20px" }}
							>
								<div
									className='benefit-of-google-text-container'
									style={{
										border:
											id === activeGoogleBenefit &&
											"1px solid rgba(22, 34, 51, 0.1)",
										background: "#fff",
									}}
								>
									<p className='font-18 font-semibold'>
										How Customers Search For Your Business
									</p>
									{id === activeGoogleBenefit ? (
										<img src={minus} />
									) : (
										<img src={plus} />
									)}
								</div>
								{activeGoogleBenefit === id && (
									<p className='benefit-of-google-answer font-regular l-height-30'>
										The practice to optimize your website for a specific local
										area is known as local SEO. Local SEO is most suitable for
										local businesses such as shops, restaurants, and agencies
										who want their web pages to rank for specific search queries
										performed by the local audiences in that area. In addition,
										local SEO helps small or local businesses to improve their
										presence and boost sales revenue online.
									</p>
								)}
							</div>
						))}
					</div>
					<div className='gmb-management-services-img-container'>
						<img src={img} />
					</div>
				</div>
				<div className='item-center mt-77'>
					<button className='font-bold benefits-of-google-btn font-25'>
						Request Free Consultation
					</button>
				</div>
			</div>
		</div>
	)
}
