import "./Why_Choose_Us.css"
import img1 from "./images/img-1.png"
import img2 from "./images/img-2.png"
import img3 from "./images/img-3.png"
import img4 from "./images/img-4.png"
import img5 from "./images/img-5.png"
import img6 from "./images/img-6.png"
import img7 from "./images/img-7.png"
import img8 from "./images/img-8.png"

export default function Why_Choose_Us() {
	return (
		<div className='why-choose-us-wrapper'>
			<div className='height-100'></div>
			<div className='row'>
				<h1 className='font-57 color-white font-extrabold text-center'>
					Why to choose us
				</h1>
				<div className='height-100'></div>
				<div className='why-choose-us-card-container'>
					<div className='why-choose-us-card'>
						<img src={img1} />
						<p className='font-18 font-bold text-center color-white mt-25 text-uppercase'>
							support available at <br />
							all times
						</p>
					</div>
					<div className='why-choose-us-card'>
						<img src={img2} />
						<p className='font-18 font-bold text-center color-white mt-25 text-uppercase'>
							satisfaction
							<br /> guranteed
						</p>
					</div>
					<div className='why-choose-us-card'>
						<img src={img3} />
						<p className='font-18 font-bold text-center color-white mt-25 text-uppercase'>
							the content is
							<br /> completly original
						</p>
					</div>
					<div className='why-choose-us-card'>
						<img src={img4} />
						<p className='font-18 font-bold text-center color-white mt-25 text-uppercase'>
							Premium quality designs
						</p>
					</div>
					<div className='why-choose-us-card'>
						<img src={img5} />
						<p className='font-18 font-bold text-center color-white mt-25 text-uppercase'>
							compititive pricing
						</p>
					</div>
					<div className='why-choose-us-card'>
						<img src={img6} />
						<p className='font-18 font-bold text-center color-white mt-25 text-uppercase'>
							Exceptional identity
						</p>
					</div>
					<div className='why-choose-us-card'>
						<img src={img7} />
						<p className='font-18 font-bold text-center color-white mt-25 text-uppercase'>
							Branding experience
						</p>
					</div>
					<div className='why-choose-us-card'>
						<img src={img8} />
						<p className='font-18 font-bold text-center color-white mt-25 text-uppercase'>
							Experience designers
						</p>
					</div>
				</div>
			</div>
			<div className='height-100'></div>
		</div>
	)
}
